<template>
    <div class="error-area ptb-100">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-content-wrap">
                    <div class="error-image">
                        <img src="../../assets/images/banner/error.webp" alt="image">
                    </div>
                    <p>The page you were looking for could not be found.</p>

                    <router-link to="/" class="default-btn">
                        Return To Home Page
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorPage',
}
</script>

<style lang="scss">
.error-area {
    text-align: center;
    position: relative;
    z-index: 1;
    .d-table {
        width: 100%;
        height: 100%;
        .d-table-cell {
            vertical-align: middle;
        }
    }
    .error-content-wrap {
        z-index: 1;
        position: relative;
        h3 {
            margin: 30px 0 0;
            position: relative;
            color: var(--headingColor);
        }
        p {
            margin: 20px 0 20px;
            font-size: 19px;
            color: var(--mainColor);
        }
        .default-btn {
            &.two {
                font-size: 16px;
                padding: 12px 40px;
            }
        }
    }
}
</style>