<template>
    <div class="testimonial-area pt-100 testimonial-area-style3">
        <div class="container">
            <div class="section-title">
                <p class="title-tag">
                    <i class="flaticon-full-stop"></i>
                    OUR CLIENT TESTIMONIAL
                </p>
                <h2>Loved By Thousand Clients</h2>
            </div>
            <div class="inner-testimonial">
                <div class="user-list">
                    <img src="../../assets/images/clients/user1.webp" class="user-1" alt="image">
                    <img src="../../assets/images/clients/user2.webp" class="user-2" alt="image">
                    <img src="../../assets/images/clients/user3.webp" class="user-3" alt="image">
                </div>
                <div class="test-slide-widget">
                    <div class="testimonial-slider">
                        <carousel
                            :autoplay="5000"
                            :wrap-around="true"
                            ref="carousel"
                        >
                            <Slide 
                                v-for="testimonial in testimonials"
                                :key="testimonial.id"
                            >
                                <div class="testimonial-content testimonial-content-style3">
                                    <div class="testimonial-self d-flex">
                                        <div class="image">
                                            <img :src="testimonial.image" alt="image">
                                        </div>
                                        <div class="content">
                                            <h3>{{testimonial.name}}</h3>
                                            <p>{{testimonial.position}}</p>
                                        </div>
                                    </div>
                                    <p class="main-p ">{{testimonial.desc}}</p>
                                    <div class="icon">
                                        <i class="flaticon-quotation"></i>
                                    </div>
                                    <div class="star">
                                        <ul>
                                            <li>
                                                <i class='bx bxs-star'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star'></i>
                                            </li>
                                            <li>
                                                <i class='bx bxs-star'></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Slide>
                        </carousel>

                        <div class="custom-navigation">
                            <button class="carousel-prev" @click="prev"><img src="../../assets/images/svgs/slider2.svg" alt="svg"></button>
                            <button class="carousel-next" @click="next"><img src="../../assets/images/svgs/slider1.svg" alt="svg"></button>
                        </div>
                    </div>
                </div>
                <div class="user-list2">
                    <img src="../../assets/images/clients/user4.webp" class="user-4 user-50" alt="image">
                    <img src="../../assets/images/clients/user5.webp" class="user-5 user-51" alt="image">
                    <img src="../../assets/images/clients/user6.webp" class="user-6 user-52" alt="image">
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/testimonial-shape2.webp" class="testimonial-shape2" alt="image">
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'OurClientTestimonial',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        testimonials: [
            {
                id: 1,
                image: require("../../assets/images/clients/t-client1.webp"),
                name: 'Christian Aaron',
                position: 'Product Manager',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit telluslu ctus ullam corper mattis pulvinar dapibus leorem vnar ipsum dolor sitam consectetur adipiscing elitut elit tellu luctus pulvinar dapibus leolorem ipsum dolor sit amet, consectetur adipiscing elit.',
            },
            {
                id: 2,
                image: require("../../assets/images/clients/t-client2.webp"),
                name: 'Gilbert Jon',
                position: 'Web Developer',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit telluslu ctus ullam corper mattis pulvinar dapibus leorem vnar ipsum dolor sitam consectetur adipiscing elitut elit tellu luctus pulvinar dapibus leolorem ipsum dolor sit amet, consectetur adipiscing elit.',
            },
        ],
    }),
    methods: {
        next() {
            this.$refs.carousel.next()
        },
        prev() {
            this.$refs.carousel.prev()
        },
    }
})
</script>

<style lang="scss">
.testimonial-area-style3 {
    .inner-testimonial {
        .test-slide-widget {
            .testimonial-slider {
                .custom-navigation {
                    .carousel-prev, .carousel-next {
                        background: #F4F8FC !important;
                        transition: all 0.5s;
                        img {
                            filter: brightness(0) saturate(100%) invert(85%) sepia(0%) saturate(1%) hue-rotate(222deg) brightness(90%) contrast(84%);
                        }
                        &:hover {
                            background-color: var(--mainColor) !important;
                            color: var(--bodyColor) !important;
                        }
                    }
                }
            }
        }
    }
}
.inner-testimonial {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .test-slide-widget {
        position: relative;
        margin-left: 0px;

        .testimonial-slider {
            .custom-navigation {
                position: relative !important;
                right: 0 !important;
                top: auto !important;
                margin-top: 30px !important;
                bottom: 0 !important;
                text-align: center;
            }
            .carousel {
                text-align: left;
            }
        }
    }
    .testimonial-content {
        position: relative;
        margin-bottom: 50px;
        background: #34354C;
        &:before {
            content: "";
            position: absolute;
            left: 20px;
            bottom: -20px;
            background: rgba(52, 53, 76, 0.53);
            right: 20px;
            border-radius: 5px;
            z-index: -1;
            height: 100%;
        }
        &::after {
            content: "";
            position: absolute;
            left: 40px;
            bottom: -40px;
            background: rgba(52, 53, 76, 0.33);
            right: 40px;
            border-radius: 5px;
            z-index: -1;
            height: 100%;
        }
    }
}
.testimonial-area {
    .section-title {
        h2 {
            color: #ffffff;
        }
    }
}
.user-list {
    .user-1 {
        position: absolute;
        top: -37px;
        left: -291px;
    }
    .user-2 {
        position: absolute;
        top: 35%;
        left: -23%;
    }
    .user-3 {
        position: absolute;
        bottom: 76px;
        left: -41%;
    }
    img {
        border-radius: 50%;
    }
}
.user-list2 {
    .user-4 {
        position: absolute;
        top: 47px;
        right: -27%;
    }
    .user-50 {
        top: -66px;
        right: -31%;
    }
    .user-51 {
        right: -17% !important;
        top: 28% !important;
    }
    .user-52 {
        bottom: 115px !important;
        right: -37% !important;
    }
    .user-5 {
        position: absolute;
        right: -45%;
        top: 39%;
    }
    .user-6 {
        position: absolute;
        bottom: 72px;
        right: -28%;
    }
    img {
        border-radius: 50%;
    }
}
.testimonial-area-style3 {
    position: relative;
    z-index: 1;
    .testimonial-shape2 {
        position: absolute;
        left: 240px;
        top: 0;
        z-index: -1;
    }
    .section-title {
        h2 {
            color: var(--headingColor);
        }
    }
}
.testimonial-content-style3 {
    background-color: var(--whiteColor) !important;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
    margin: 20px;
    &::before {
        background: #FFFFFF !important;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
        z-index: -1;
    }
    &::after {
        background: #FFFFFF !important;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
        z-index: -2 !important;
    }
    .testimonial-self {
        .content {
            h3 {
                color: var(--headingColor) !important;
            }
            p {
                color: #888888 !important;
            }
        }
    }
    .main-p {
        color: #555555 !important;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .testimonial-area-style3 {
        .testimonial-shape2 {
            left: 0;
        }
        .inner-testimonial {
            .test-slide-widget {
                .testimonial-slider {
                    .custom-navigation {
                        margin-top: 0 !important;
                    }
                }
            }
        }
        .test-slide-widget {
            .testimonial-slider {
                .custom-navigation {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    .testimonial-client {
        display: none;
    }
        .testimonial-area {
        .single-section {
            margin-bottom: 15px;
        }
    }
    .test-slide-widget {
        margin-left: 0;
    }
    .client-box {
        top: 0;
        position: relative;
        margin-bottom: -50px;
        padding: 20px 15px;
    }
    .testimonial-content {
        padding: 30px 20px 30px 20px;
        .testimonial-self {
            margin-bottom: 20px;
            .image {
                margin-right: 20px;
                img {
                    width: 60px;
                }
            }
            .content {
                h3 {
                    font-size: 15px;
                    margin-bottom: 5px;
                }
            }
        }
        .icon {
            position: absolute;
            top: 11%;
            right: 5%;
            i {
                font-size: 25px;
            }
        }
        .main-p {
            font-size: 14px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-client {
        position: relative;
        display: none;
    }
    .testimonial-area {
        .single-section {
            margin-bottom: 30px;
        }
    }
    .testimonial-area-style3 {
        .testimonial-shape2 {
            left: 0;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-client {
        display: none;
    }
    .testimonial-content {
        padding: 35px 25px 35px 35px;
        .testimonial-self {
            margin-bottom: 20px;
            .image {
                img {
                    width: 60px;
                }
            }
            .content {
                h3 {
                    font-size: 18px;
                    margin-bottom: 8px;
                }
            }
        }
        .main-p {
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
    .testimonial-area-style3 {
        .testimonial-shape2 {
            left: 0;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-client {
        ul {
            li {
                &:nth-child(1) {
                    left: 424px;
                }
                &:nth-child(3) {
                    left: 87px;
                }
                &:nth-child(2) {
                    top: -82px;
                    left: 305px;
                }
                &:nth-child(4) {
                    left: 134px;
                    top: 2px;
                }
                &:nth-child(6) {
                    position: relative;
                    left: 290px;
                    top: 24px;
                }
                &:nth-child(5) {
                    position: relative;
                    top: -35px;
                    left: 80px;
                }
                &:nth-child(7) {
                    left: 5px;
                    top: -5px;
                }
                &:nth-child(9) {
                    top: 22px;
                    position: relative;
                    left: 0;
                }
            }
        }
        .ball-3 {
            position: absolute;
            top: 165px;
            right: 252px;
        }
        .ball-5 {
            display: none;
        }
        .ball-2 {
            position: absolute;
            top: 69px;
            right: 80px;
        }
        .ball-4 {
            display: none;
        }
    }
    .testimonial-area-style3 {
        .testimonial-shape2 {
            left: 0;
        }
    }
}

/* Min width 1400px to Max width 1799px */
@media only screen and (min-width: 1400px) and (max-width: 1799px) {
    .testimonial-area-style3 {
        .testimonial-shape2 {
            left: 0;
        }
    }
}
</style>