<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Testimonial" />
        <TestimonialContent />
        <PartnerLogo class="partner-logo-area pt-100 pb-75 inner-partner-logo" />
        <TeamAdvisor />
        <SubscribeNewsletter />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import TestimonialContent from '../Testimonials/TestimonialContent'
import PartnerLogo from '../Common/PartnerLogo'
import TeamAdvisor from '../Testimonials/TeamAdvisor'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'TestimonialPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        TestimonialContent,
        PartnerLogo,
        TeamAdvisor,
        SubscribeNewsletter,
        MainFooter,
    }
}
</script>