<template>
    <div class="login-area ptb-100">
        <div class="container">
            <div class="login-widget">
                <h2>Started With a Free Account
                </h2>
                <p>
                    Sign up in seconds!
                </p>
                <form>
                    <div class="row">
                        <div class="col-lg-12">
                            <label for="formGroupExampleInput" class="form-label">Email Address</label>
                            <input type="text" class="form-control" id="formGroupExampleInput">
                        </div>
                        <div class="col-lg-12">
                            <label for="formGroupExampleInput2" class="form-label">Password</label>
                            <input type="text" class="form-control" id="formGroupExampleInput2">
                        </div>
                        <div class="col-lg-12">
                            <label for="formGroupExampleInput3" class="form-label">Company</label>
                            <input type="text" class="form-control" id="formGroupExampleInput3">
                        </div>
                        <div class="col-lg-12">
                            <label for="formGroupExampleInput4" class="form-label">Name</label>
                            <input type="text" class="form-control" id="formGroupExampleInput4">
                        </div>
                        <div class="col-lg-12">
                            <div class="divide">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        I agree to the <router-link to="/terms-conditions">Terms of Use</router-link>,<router-link to="/privacy-policy"> Privacy Policy</router-link> and the <a href="#">Anti-Spam Policy</a>.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <button type="submit" class="default-btn">Register</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterContent'
}
</script>

<style lang="scss">
.login-widget {
    max-width: 720px;
    margin: auto;
    h2 {
        font-size: 48px;
        margin-bottom: 5px;
    }
    p {
        a {
            color: var(--mainColor);
            text-decoration: underline;
            &:hover {
                color: var(--hoverColor);
            }
        }
        margin-bottom: 30px;
    }
    .form-label {
        font-weight: 500;
        font-size: 16px;
        color: var(--headingColor);
    }
    .form-control {
        height: 60px;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        margin-bottom: 20px;
        &:focus {
            box-shadow: unset;
        }
    }
    .divide {
        margin-bottom: 20px;
        &.tow {
            &:last-child {
                text-align: end;
                a {
                    color: var(--mainColor);
                    font-weight: 500;
                    text-decoration: underline;
                }
            }
        }
        a {
            color: var(--mainColor);
            font-weight: 500;
            text-decoration: underline;
        }
        .form-check-input[type=checkbox] {
            &:focus {
                box-shadow: unset;
            }
        }
    }
    .default-btn {
        width: 100%;
    }
}
</style>