<template>
    <div class="inner-team-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>Meet Our Team members</span>
                <h2>People Behind Our Success Expert Team</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="team-box inner-team-box">
                        <div class="image">
                            <img src="../../assets/images/team/member1.webp" alt="image">
                        </div>
                        <div class="content">
                            <h4>
                                Christian Aaron 
                            </h4>
                            <p>Marketing Manager</p>
                            <div class="top-social">
                                <ul>
                                    <li>
                                        Follow Us 
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/project-shape.webp" class="project-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-box inner-team-box">
                        <div class="image">
                            <img src="../../assets/images/team/member2.webp" alt="image">
                        </div>
                        <div class="content">
                            <h4>
                                Charlotte Amelia
                            </h4>
                            <p>Co-Founder</p>
                            <div class="top-social">
                                <ul>
                                    <li>
                                        Follow Us 
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/project-shape.webp" class="project-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-box inner-team-box">
                        <div class="image">
                            <img src="../../assets/images/team/member3.webp" alt="image">
                        </div>
                        <div class="content">
                            <h4>
                                Jonathan Santiago 
                            </h4>
                            <p>Product Manager</p>
                            <div class="top-social">
                                <ul>
                                    <li>
                                        Follow Us 
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/project-shape.webp" class="project-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-box inner-team-box">
                        <div class="image">
                            <img src="../../assets/images/team/member4.webp" alt="image">
                        </div>
                        <div class="content">
                            <h4>
                                Emersyn Sienna
                            </h4>
                            <p>Marketing Manager</p>
                            <div class="top-social">
                                <ul>
                                    <li>
                                        Follow Us 
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/project-shape.webp" class="project-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-box inner-team-box">
                        <div class="image">
                            <img src="../../assets/images/team/member5.webp" alt="image">
                        </div>
                        <div class="content">
                            <h4> Antonio Justin</h4>
                            <p>Co-Founder</p>
                            <div class="top-social">
                                <ul>
                                    <li>
                                        Follow Us 
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/project-shape.webp" class="project-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="team-box inner-team-box">
                        <div class="image">
                            <img src="../../assets/images/team/member6.webp" alt="image">
                        </div>
                        <div class="content">
                            <h4>
                                BriannaRosalie 
                            </h4>
                            <p>Product Manager</p>
                            <div class="top-social">
                                <ul>
                                    <li>
                                        Follow Us 
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/project-shape.webp" class="project-shape" alt="image">
                    </div>
                </div>
            </div>
            <div class="result-pagination search-pagination">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">
                                    <img src="../../assets/images/svgs/slider2.svg" alt="svg">
                                </span>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link active" href="#">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">
                                    <img src="../../assets/images/svgs/slider1.svg" alt="svg">
                                </span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamMembers'
}
</script>

<style lang="scss">
.team-box {
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    .content {
        text-align: center;
        margin-top: 25px;
        h4 {
            font-size: 22px;
            margin-bottom: 3px;
            a {
                color: var(--headingColor);
                &:hover {
                    color: var(--hoverColor);
                }
            }
        }
        p {
            margin-bottom: 10px;
            color: var(--hoverColor);
        }
        .top-social {
            text-align: center;
            ul {
                li {
                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    .image {
        img {
            border-radius: 5px;
        }
    }
    .project-shape {
        position: absolute;
        z-index: 9;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
    }
    &:hover {
        .project-shape {
            opacity: 1;
            visibility: visible;
        }
    }
}
.inner-team-area {
    .section-title {
        max-width: 660px;
        margin-left: auto;
        margin-right: auto;
    }
}
.inner-team-box {
    margin-bottom: 25px;
}
</style>