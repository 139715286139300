<template>
    <div class="choose-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="single-section choose-content">
                        <span>Why Choose Us</span>
                        <h2>Enabling Digital Transformation Together.</h2>
                        <p>With years of experience and a team of highly skilled professionals, we are dedicated to
                            delivering top-notch solutions tailored to your specific requirements. We offer a seamless and
                            transparent process that keeps you informed every step of the way.</p>
                        <p class="last-p">Partner with us and boost your digital presence and get one step closer to
                            achieving your Brand Success.</p>
                        <a href="/contact" class="default-btn">Let's Talk Today</a>
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="choose-shape2" alt="image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="choose-widget">
                        <div class="row">
                            <div class="col-lg-9 col-md-9" data-aos="fade-right" data-aos-duration="1000">
                                <div class="choose-box">
                                    <div class="icon">
                                        <img src="../../assets/images/svgs/choose1.svg" class="choose" alt="svg">
                                        <img src="../../assets/images/svgs/choose-hide1.svg" class="choose-hide" alt="svg">
                                    </div>
                                    <div class="content">
                                        <p>Software</p>
                                        <h4>User Oriented Approach</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9" data-aos="fade-up" data-aos-duration="800">
                                <div class="choose-box style">
                                    <div class="icon">
                                        <img src="../../assets/images/svgs/choose2.svg" class="choose" alt="svg">
                                        <img src="../../assets/images/svgs/choose-hide2.svg" class="choose-hide" alt="svg">
                                    </div>
                                    <div class="content">
                                        <p>Cloud</p>
                                        <h4>Software solutions</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9" data-aos="fade-right" data-aos-duration="1000">
                                <div class="choose-box">
                                    <div class="icon">
                                        <img src="../../assets/images/svgs/choose3.svg" class="choose" alt="svg">
                                        <img src="../../assets/images/svgs/choose-hide3.svg" class="choose-hide" alt="svg">
                                    </div>
                                    <div class="content">
                                        <p>Software</p>
                                        <h4>Team Augmentation</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/choose-shape1.webp" class="choose-shape" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/choose-shape2.webp" class="choose-border" alt="image">
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>

<style lang="scss">
.choose-area {
    position: relative;
    z-index: 1;

    .choose-border {
        position: absolute;
        left: 125px;
        bottom: 0;
        z-index: -1;
    }
}

.choose-box {
    position: relative;
    padding: 32px 35px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .icon {
        position: relative;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        line-height: 65px;
        text-align: center;
        margin-right: 20px;
        transition: var(--transition);
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            width: 100%;
            opacity: 0;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            background: rgba(0, 100, 251, 0.1);
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            transition: var(--transition);
            background: rgba(255, 65, 75, 0.1);
        }

        .choose-hide {
            position: absolute;
            left: 12px;
            top: 11px;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
        }

        .choose {
            transition: var(--transition);
        }
    }

    .content {
        p {
            margin-bottom: 2px;
        }

        h4 {
            font-size: 22px;
            margin-bottom: 0;
        }
    }

    &:hover {
        .icon {
            &::before {
                opacity: 1;
                visibility: visible;
                height: 100%;
            }

            &::after {
                opacity: 0;
                visibility: hidden;
            }

            .choose-hide {
                opacity: 1;
                visibility: visible;
            }

            .choose {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &.style {
        position: relative;
        right: -75px;
    }
}

.choose-content {
    position: relative;
    padding-right: 20px;

    .last-p {
        margin-bottom: 25px;
    }

    .choose-shape2 {
        position: absolute;
        top: -67px;
        left: 25%;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
    }
}

.choose-widget {
    position: relative;
    z-index: 1;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 15px;

    .choose-shape {
        position: absolute;
        top: -40px;
        text-align: center;
        right: 0;
        z-index: -1;
        -webkit-animation-name: rotateme;
        animation-name: rotateme;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .choose-area {
        .choose-border {
            display: none;
        }
    }

    .choose-content {
        margin-bottom: 30px;
        padding-left: 0;
    }

    .choose-box {
        padding: 25px 20px;
        margin-bottom: 20px;

        .content {
            h4 {
                font-size: 16px;
                margin-bottom: 0;
            }
        }

        .icon {
            width: 50px;
            height: 50px;
            line-height: 47px;
            margin-right: 10px;

            img {
                height: 30px;
            }
        }

        &.style {
            position: relative;
            right: 0;
        }
    }

    .choose-widget {
        .choose-shape {
            display: none;
        }

        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .choose-area {
        .choose-border {
            display: none;
        }
    }

    .choose-box.style {
        position: relative;
        right: -75px;
    }

    .choose-content {
        position: relative;
        padding-left: 0;
    }

    .choose-widget {
        margin-left: 0;
        margin-top: 40px;

        .choose-shape {
            animation-name: unset;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .choose-box {
        padding: 30px 15px;

        .content {
            h4 {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }

    .choose-area {
        .choose-border {
            left: 0;
        }
    }

    .choose-widget {
        .choose-shape {
            animation-name: unset;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .choose-widget {
        .choose-shape {
            animation-name: unset;
        }
    }

    .choose-area {
        .choose-border {
            left: 0;
        }
    }

    .choose-box {
        padding: 25px 20px;

        .content {
            h4 {
                font-size: 20px;
            }
        }
    }
}</style>