<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Our Price" />
        <!-- <ChooseYourBestPlans /> -->
        <TrustedContent />
        <FunFect class="pt-100" />
        <SubscribeNewsletter />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
// import ChooseYourBestPlans from '../Common/ChooseYourBestPlans'
import TrustedContent from '../Pricing/TrustedContent'
import FunFect from '../Common/FunFect'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'PricingPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        // ChooseYourBestPlans,
        TrustedContent,
        FunFect,
        SubscribeNewsletter,
        MainFooter,
    }
}
</script>