<template>
    <div>
        <div class="banner-area-style3">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="style3-banner-image">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 p-0">
                                    <div class="col-lg-12 p-0 col-md-12">
                                        <img src="../../assets/images/banner/banner-grid1.jpg" class="banner-grid1" alt="image">
                                    </div>
                                    <div class="col-lg-12 p-0 col-md-12">
                                        <div class="grid-image">
                                            <img src="../../assets/images/banner/banner-grid2.jpg" class="banner-grid2" alt="image">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="banner3-big">
                                        <img src="../../assets/images/banner/banner-grid3.jpg" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="banner-content banner-content-style3">
                            <p class="title-tag">
                                <i class="flaticon-full-stop"></i>
                                VNET BEST SOFTWARE COMPANY
                            </p>
                            <h1>Better Software For A Safer Tomorrow</h1>
                            <p class="banner-p">Nulla quis feugiat mi. Mauris tincidunt dui massa, non feugiat ex blandit vel. Nam lacinia dapibus nisi accon est tempor ac. Sed ultrices eros id tristique nec fringilla augue dui ac magna.  </p>

                            <div class="banner-btn d-flex">
                                <router-link to="/contact" class="default-btn">
                                    Get In Touch
                                </router-link>
                                <router-link to="/singup" class="default-btn default-btn-style2">
                                    Sign Up Free
                                </router-link>
                            </div>

                            <p class="link-p2"> <i class='bx bxs-star'></i> Rated 4.9/5 from over 600 reviews. 
                            </p>
                            <div class="image-with-video">
                                <div class="image-video">
                                    <img src="../../assets/images/banner/banner-video1.webp" alt="image">
                                    <div 
                                        class="popup-youtube"
                                        v-on:click="isPopupMethod(isPopup)"
                                        style="cursor: pointer"
                                    >
                                        <div class="icon-play">
                                            <i class="flaticon-play-1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/9dE0DkGcPlk" title="Webtecknow - Affordable Solutions for all your Creativity needs" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>

<style lang="scss">
.banner-area-style3 {
    background-image: url(../../assets/images/banner/banner3-bg.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 145px;
    padding-bottom: 220px;
}
.style3-banner-image {
    margin-right: -20px;
    margin-left: 10px;
    .banner-grid2 {
        margin-top: 20px;
        width: 190px;
        height: 160px;
        position: absolute;
        right: 0;
    }
    .grid-image {
        position: relative;
    }
    img {
        border-radius: 5px;
    }
}
.title-tag {
    letter-spacing: 0.2em;
    color: var(--hoverColor);
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    display: inline-block;
    i.flaticon-full-stop {
        font-size: 70px;
        position: absolute;
        left: -29px;
        top: -39px;
    }
}
.banner-content-style3 {
    position: relative;
    h1 {
        font-weight: 900;
        font-size: 75px;
        line-height: 90px;
        color: #FFFFFF;
    }
    .link-p2 {
        margin-bottom: 0;
        color: #CCCCCC;
        i {
            height: 20px;
            width: 20px;
            background-color: #FFC107;
            text-align: center;
            color: #ffffff;
            font-size: 14px;
            line-height: 20px;
            border-radius: 3px;
            margin-right: 12px;
        }
    }
    .banner-p {
        color: #CCCCCC;
        margin-bottom: 25px;
    }
    .banner-btn {
        margin-bottom: 20px;
        .default-btn-style2 {
            margin-left: 20px;
            background: rgba(255, 255, 255, 0.07);
            color: var(--whiteColor);
        }
    }
}
.image-with-video {
    position: absolute;
    right: 85px;
    bottom: -85px;
    .image-video {
        position: relative;
        text-align: center;
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }
    }
    .popup-youtube {
        position: absolute;
        left: 0;
        display: inline-block;
        right: 0;
        color: #fff;
        font-size: 30px;
        line-height: 150px;
    }
}
.banner3-big {
    margin-right: 85px;
}
</style>