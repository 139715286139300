<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-image">
                        <img src="../../assets/images/about/about2.webp" alt="image">
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="single-section about-content">
                        <ul class="section-style">
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                            <li>
                                About Our Company
                            </li>
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                        </ul>
                        <h2>Over 12 Year Professional Experiences</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus  ullamcorper mattis pulvinar dapibus leoorem ipsum dolor sit amet, consectetu adipiscing elitut elit tellu luctus pulvinar dapibus leo.</p>
                        <p class="last-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus ull mattis pulvinar dapibus leoorem ipsum.</p>
                        <router-link to="/about" class="default-btn">More About Us</router-link>
                        <img src="../../assets/images/shapes/about-shape2.webp" class="about-shape02" alt="image">
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="about-shape03" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/about-shape1.webp" class="about-shape04" alt="image">
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany'
}
</script>

<style lang="scss">
.about-content {
    position: relative;
    .about-shape02 {
        position: absolute;
        top: -132px;
        left: 56%;
    }
    .about-shape03 {
        position: absolute;
        bottom: -110px;
        left: 88%;
    }
    
}
.about-image {
    margin-right: 45px;
    position: relative;
    .about-shape {
        position: absolute;
        top: 28px;
        left: 23%;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
    }
}
.about-area {
    position: relative;
    z-index: 1;
    .about-shape2 {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: -1;
    }
}
.about-shape04 {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: all ease 0.8s;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .about-image {
        margin-right: 0;
        position: relative;
        margin-bottom: 30px;
    }
    .about-content {
        margin-left: 0;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image {
        margin-right: 0;
        margin-bottom: 30px;
    }
    .about-content {
        margin-left: 0;
    }
}
</style>