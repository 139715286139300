<template>
    <div class="privacy-policy-area pt-100 pb-75">
        <div class="container">
            <div class="privacy-policy-wrap">
                <div class="privacy-content">
                    <h3>Privacy Policy</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore fugiat.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip</p> -->
                    <ul>
                        <li>
                            <i class='bx bx-check-double'></i>
                            Your Privacy is of utmost importance to us. It is our policy to respect your privacy regarding
                            any information we may collect from you across our or any other website we own and operate.
                        </li>
                        <li>
                            <i class='bx bx-check-double'></i>
                            Personal information will be requested only when it is necessary to provide you the service you
                            wish to seek. You share it with your knowledge and consent. We will be keeping complete
                            transparency as to how and where your personal information is being utilised.
                        </li>
                        <li>
                            <i class='bx bx-check-double'></i>
                            Any information you share will not be shared publicly or with any outside party unless required
                            by the Law.
                        </li>
                        <li>
                            <i class='bx bx-check-double'></i>
                            We won’t accept responsibility for the data or any other content which is shared on any other
                            website which may be accessed via our site which is not under our control.
                        </li>
                        <li>
                            <i class='bx bx-check-double'></i>
                            You can choose what data is to be shared and what is not as per your convenience, with the
                            understanding that we may be unable to provide you with some of your desired services.
                        </li>
                        <li>
                            <i class='bx bx-check-double'></i>
                            Your continued use of our site and services will be regarded as acceptance of our practices
                            around privacy and personal information. If you have any questions or queries regarding our
                            handling of data and personal information, feel free to contact us.
                        </li>
                        <li>
                            <i class='bx bx-check-double'></i>
                            Policy has been placed into effect as of 1 July 2023.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>

<style lang="scss">
.privacy-policy-wrap {
    .title {
        margin-bottom: 30px;
        text-align: center;

        h2 {
            font-size: 36px;
            font-weight: 900;
            color: var(--headingColor);
            font: 30px;
        }
    }

    img {
        margin-bottom: 30px;
    }

    .privacy-content {
        margin-bottom: 30px;

        h3 {
            margin-bottom: 15px;
            font-weight: 700;
            color: var(--headingColor);
            font-size: 26px;
        }

        p {
            margin-bottom: 10px;
        }

        ul {
            margin-left: 0;
            margin-bottom: 15px;
            list-style-type: none;
            padding-left: 0;
            margin-top: 15px;
        }

        li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                position: absolute;
                left: 0;
                top: 1px;
                color: var(--mainColor);
                font-size: 20px;
            }
        }
    }
}</style>