<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Privacy Policy" />
        <PrivacyPolicy />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        PrivacyPolicy,
        MainFooter,
    }
}
</script>