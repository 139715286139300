<template>
    <div class="terms-conditions-area pt-100 pb-75">
        <div class="container">
            <div class="terms-conditions">
                <div class="conditions-content">
                    <h3>1. Acceptance of Terms</h3>
                    <p>Acceptance of Terms By accessing or using the services provided by our IT service company (referred
                        to as "we," "us," or "our"), you acknowledge that you have read, understood, and agree to be bound
                        by these Terms of Service. If you do not agree with any part of these terms, you should not access
                        or use our services.</p>
                    <!-- <p>truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself,
                        ecause it is pleasure, but because those who do not know how to pursue pleasure rationally encounter
                        consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires
                        to.</p> -->

                    <!-- <ul>
                        <li>
                            <i class='bx bx-check'></i>
                            It has survived not only five centuries, but also the leap into electronic typesetting.
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Contrary to popular belief, Lorem Ipsum is not simply random text.
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                        </li>
                    </ul> -->
                </div>

                <div class="conditions-content">
                    <h3>2. Scope of Services</h3>
                    <p>We company offers various services, including but not limited to software development, IT consulting,
                        system maintenance, network support, and cloud solutions. The specific details and scope of the
                        services will be outlined in the corresponding agreement or statement of work.
                    </p>
                </div>

                <div class="conditions-content">
                    <h3>3. Service Delivery</h3>
                    <p>We will make reasonable efforts to deliver services according to the agreed-upon timelines and
                        specifications. However, delays or disruptions may occur due to factors beyond our control. We will
                        not be held liable for any losses or damages arising from such delays or disruptions.</p>
                </div>

                <div class="conditions-content">
                    <h3>4. Client Obligations</h3>
                    <p>To ensure the smooth provision of services, the client agrees to: </p>
                    <ul>
                        <li>
                            <i class='bx bx-check'></i>
                            Provide accurate and complete information necessary for service delivery.
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Cooperate with our personnel and provide timely feedback and approvals as required.
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Provide access to relevant systems, networks, and infrastructure as needed for service delivery.
                        </li>
                        <li>
                            <i class='bx bx-check'></i>
                            Comply with all applicable laws and regulations while using our services.
                        </li>
                    </ul>
                </div>

                <div class="conditions-content five">
                    <h3>5. Fees and Payment</h3>
                    <p>The client agrees to pay the agreed-upon fees for the services provided by our company. Payment terms, including invoicing schedules and payment methods, will be outlined in the corresponding agreement. Late payments may be subject to interest charges or other penalties as specified.</p>
                </div>
                <div class="conditions-content ">
                    <h3>6. Intellectual Property</h3>
                    <p>Any pre-existing intellectual property rights of either party remain the sole property of that party. Unless explicitly stated otherwise, any intellectual property developed or created during the provision of services shall be owned by our IT service company. The client is granted a non-exclusive, non-transferable license to use such intellectual property solely for the purpose intended.</p>
                </div>
                <div class="conditions-content five">
                    <h3>7. Limitation of Liability</h3>
                    <p>To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or related to the provision of services, even if advised of the possibility of such damages. The client agrees to indemnify and hold us harmless from any claims or liabilities arising from their use of the services.</p>
                </div>
                <div class="conditions-content five">
                    <h3>8. Amendments</h3>
                    <p>TWe reserve the right to modify or update these Terms of Service at any time without prior notice. The most current version will be made available on our website. Continued use of our services after any modifications constitutes acceptance of the updated terms.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsConditions'
}
</script>

<style lang="scss">
.terms-conditions {
    .title {
        margin-bottom: 30px;
        text-align: center;

        span {
            color: var(--mainColor);
            display: block;
            margin-bottom: 10px;
        }

        h2 {
            font-size: 36px;
            color: var(--headingColor);
            font-weight: 900;
        }
    }

    img {
        margin-bottom: 30px;
    }

    .conditions-content {
        margin-bottom: 30px;

        h3 {
            margin-bottom: 15px;
            color: var(--headingColor);
        }

        p {
            margin-bottom: 10px;
        }

        ul {
            margin-bottom: 15px;
            padding-left: 0;
            list-style-type: none;
            margin-top: 15px;

            li {
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                i {
                    position: absolute;
                    left: 0;
                    top: 1px;
                    font-size: 20px;
                    color: var(--mainColor);
                }
            }
        }

        h3 {
            margin-bottom: 15px;
            font-weight: 700;
            color: var(--headingColor);
        }

        p {
            margin-bottom: 10px;
        }
    }
}</style>