<template>
    <div class="blog-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>Read Our Blogs</span>
                <h2>Popular Blog Post & Newssssss</h2>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">All Post</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Recent</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Popular</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent1.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">Top 10 Most Famous Technology Trend In 2022</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            4.4k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent2.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">How Technology Dominate In The New World In 2022</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            2.3k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent3.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">Necessity May Give Us Your Best Virtual Court System</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            3.0k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent1.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">Top 10 Most Famous Technology Trend In 2022</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            4.4k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent2.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">How Technology Dominate In The New World In 2022</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            2.3k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent3.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">Necessity May Give Us Your Best Virtual Court System</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            3.0k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent1.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">Top 10 Most Famous Technology Trend In 2022</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            4.4k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent2.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">How Technology Dominate In The New World In 2022</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            2.3k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <a href="blog-details.html">
                                        <img src="../../assets/images/blog/recent3.webp" alt="image">
                                    </a>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li>
                                    </ul>
                                    <h3>
                                        <a href="blog-details.html">Necessity May Give Us Your Best Virtual Court System</a>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <a href="blog-details.html" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </a>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            3.0k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogPost'
}
</script>

<style lang="scss">
.recent-content {
    padding: 30px 30px 0;
    .blog-list {
        list-style: none;
        padding-left: 0;
        li {
            display: inline-block;
            position: relative;
            padding-right: 20px;
            i {
                position: relative;
                color: var(--hoverColor);
                top: 2px;
                margin-right: 5px;
            }
        }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 15px;
        a {
            color: var(--headingColor);
            &:hover {
                color: var(--hoverColor);
            }
        }
    }
    .read-more {
        font-weight: 500;
        color: var(--mainColor);
        &:hover {
            color: var(--hoverColor);
        }
        i {
            font-size: 25px;
            position: relative;
            top: 8px;
        }
    }
    .recent-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
            display: inline-block;
            position: relative;
            padding-right: 20px;
            i {
                position: relative;
                color: var(--hoverColor);
                top: 2px;
                margin-right: 5px;
            }
            .read-more {
                font-weight: 500;
                color: var(--mainColor);
                &:hover {
                    color: var(--hoverColor);
                    i {
                        color: var(--hoverColor);
                    }
                }
                i {
                    font-size: 25px;
                    position: relative;
                    top: 8px;
                    color: var(--mainColor);
                }
            }
        }
    }
}
.blog-area {
    position: relative;
    z-index: 1;
}
.blog-card {
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    transition: var(--transition);
    &:hover {
        transform: translateY(-10px);
    }
    .blog-content {
        position: absolute;
        left: 40px;
        background: #FFFFFF;
        border-radius: 5px;
        right: 40px;
        bottom: 40px;
        padding: 50px;
        .blog-list {
            list-style: none;
            padding-left: 0;
            margin-bottom: 15px;
            li {
                display: inline-block;
                position: relative;
                padding-right: 20px;
                i {
                    position: relative;
                    color: var(--hoverColor);
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
            a {
                color: var(--headingColor);
                &:hover {
                    color: var(--hoverColor);
                }
            }
        }
        .read-more {
            font-weight: 500;
            color: var(--mainColor);
            &:hover {
                color: var(--hoverColor);
                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                }
            }
            img {
                transition: var(--transition);
            }
        }
    }
    .blog-image {
        img {
            border-radius: 5px;
        }
    }
}
#myTab {
    justify-content: center;
    margin-bottom: 50px;
    border: none;
    .nav-link {
        border: none;
        font-weight: 500;
        color: var(--bodyColor);
        position: relative;
        transition: var(--transition);
        &::before {
            content: "\f106";
            font-family: flaticon !important;
            position: absolute;
            font-size: 50px;
            top: 6px;
            font-weight: 500;
            left: 0;
            right: 0;
            color: var(--mainColor);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition)
        }
        &:hover {
            border: none;
            color: var(--headingColor);
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
        &.active {
            color: var(--headingColor);
            font-weight: 700;
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
</style>