<template>
    <div class="trusted-area">
        <div class="container">
            <div class="trusted-content">
                <h3>Preparing For Your Success Trusted Source In Software Services.</h3>
                <div class="trusted-btn">
                    <router-link to="/contact" class="default-btn">Contact Now Today</router-link>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/inner-shape.webp" class="inner-shape" alt="image">
    </div>
</template>

<script>
export default {
    name: 'TrustedContent'
}
</script>

<style lang="scss">
.trusted-area {
    background-image: url(../../assets/images/banner/inner-price.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 140px;
    padding-bottom: 140px;
    z-index: 3;
    text-align: center;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background: rgba(46, 47, 70, 0.75);
    }
    .inner-shape {
        position: absolute;
        bottom: 90px;
        right: 30%;
    }
}
.trusted-content {
    max-width: 800px;
    margin: auto;
    h3 {
        font-size: 48px;
        line-height: 65px;
        letter-spacing: -0.03em;
        color: var(--whiteColor);
        margin-bottom: 35px;
    }
}
</style>