<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbarTwo />
        <SidebarModal />
        <MainBanner />
        <PartnerLogo class="partner-style3" />
        <AboutOurCompany />
        <ServicesWeOffer />
        <VideoContent />
        <OurCaseStudy />
        <!-- <ChooseYourBestPlans /> -->
        <OurFunFect />
        <SubscribeNewsletter class="newsletter-area3" />
        <WhyChooseUs />
        <OurTeamMembers />
        <OurClientTestimonial />
        <BlogPost />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbarTwo from '../Layouts/MainNavbarTwo'
import SidebarModal from '../Layouts/SidebarModal'
import MainBanner from '../HomeThree/MainBanner'
import PartnerLogo from '../Common/PartnerLogo'
import AboutOurCompany from '../HomeThree/AboutOurCompany'
import ServicesWeOffer from '../HomeThree/ServicesWeOffer'
import VideoContent from '../HomeThree/VideoContent'
import OurCaseStudy from '../HomeThree/OurCaseStudy'
// import ChooseYourBestPlans from '../Common/ChooseYourBestPlans'
import OurFunFect from '../HomeThree/OurFunFect'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import WhyChooseUs from '../HomeThree/WhyChooseUs'
import OurTeamMembers from '../HomeThree/OurTeamMembers'
import OurClientTestimonial from '../HomeThree/OurClientTestimonial'
import BlogPost from '../Common/BlogPost'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'HomeThreePage',
    components: {
        // TopHeader,
        MainNavbarTwo,
        SidebarModal,
        MainBanner,
        PartnerLogo,
        AboutOurCompany,
        ServicesWeOffer,
        VideoContent,
        OurCaseStudy,
        // ChooseYourBestPlans,
        OurFunFect,
        SubscribeNewsletter,
        WhyChooseUs,
        OurTeamMembers,
        OurClientTestimonial,
        BlogPost,
        MainFooter,
    }
}
</script>