<template>
    <div class="any-quary-area pt-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="query-advisor">
                        <img src="../../assets/images/query/query2.webp" class="advisor-main" alt="image">
                        <img src="../../assets/images/shapes/advisor.webp" class="advisor-shape" alt="image">
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="advisor-shape2" alt="image">
                        <div class="banner-funfect">
                            <div class="single-funfact-box">
                                <img src="../../assets/images/svgs/rocket3.svg" class="fun-image" alt="image">
                                <div class="content-fun">
                                    <h3>35+</h3>
                                    <p>Team Advisor</p>
                                </div>
                                <img src="../../assets/images/shapes/b-c-shape.webp" class="q-a-shape2" alt="image">
                                <img src="../../assets/images/shapes/fun.webp" class="fun-shape" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="single-section advisor-content">
                        <ul class="section-style">
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                            <li>
                                Any Query?
                            </li>
                            <li>
                                <i class="flaticon-remove"></i>
                                <i class="flaticon-remove"></i>
                            </li>
                        </ul>
                        <h2>Contact Us Now</h2>
                        <div class="form-area">
                            <form> 
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Name">
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Email Address">
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Phone Number">
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Your Website">
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea  cols="30" rows="10" class="form-control" placeholder="Your Message Here"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit" class="default-btn">Submit Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs'
}
</script>

<style lang="scss">
.query-advisor {
    position: relative;
    z-index: 1;
    text-align: center;
    .banner-funfect {
        position: absolute;
        bottom: 125px;
        left: -45px;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
        .single-funfact-box {
            background: #0064FB;
            position: relative;
            border-radius: 5px;
            padding: 25px 35px 35px 35px;
            .fun-image {
                position: absolute;
                left: 32px;
                top: 51px;
            }
            .q-a-shape2 {
                position: absolute;
                right: 20px;
                bottom: -15px;
            }
            h3 {
                font-weight: 800;
                font-size: 48px;
                color: var(--whiteColor);
                margin-bottom: 0;
            }
            p {
                color: #EEEEEE;
                margin-bottom: 0;
            }
            .content-fun {
                padding-left: 80px;
    
            }
            .fun-shape {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .advisor-shape {
        position: absolute;
        left: 41px;
        bottom: 0;
        z-index: -1;
    }
    .advisor-shape2 {
        position: absolute;
        top: 153px;
        right: 152px;
    }
}
.advisor-content {
    .form-control {
        background: #F4F8FC;
        border-radius: 5px;
        height: 60px;
        border: none;
        padding-left: 14px;
        margin-bottom: 20px;
        color: #888888;
        &:focus {
            box-shadow: unset;
        }
        &::placeholder {
            color: #888888;
        }
    }
    textarea {
        height: 170px !important;
        padding-top: 15px;
    }
    .default-btn {
        text-align: center;
        width: 100%;
        background-color: var(--hoverColor);
        &:hover {
            background-color: var(--mainColor);
        }
    }
    h2 {
        margin-bottom: 30px;
    }
}
.uncommon-service {
    position: relative;
    z-index: 1;
    .service-shape {
        position: absolute;
        top: -50px;
        left: 0;
        z-index: -1;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .query-advisor {
        margin-bottom: 30px;
        .banner-funfect {
            position: absolute;
            bottom: 66px;
            left: 0;
            .single-funfact-box {
                padding: 20px 25px 25px 30px;
                .fun-image {
                    position: absolute;
                    left: 20px;
                    height: 35px;
                    top: 43px;
                }
                .content-fun {
                    padding-left: 35px;
                }
                h3 {
                    font-size: 30px;
                }
                
                
            }
        }
        .advisor-shape {
            left: 0;
            bottom: 0;
            z-index: -1;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .query-advisor {
        margin-bottom: 30px;
        .banner-funfect {
            left: 0;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .query-advisor {
        .banner-funfect {
            position: absolute;
            bottom: 125px;
            left: 0;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .query-advisor {
        .banner-funfect {
            left: 0;
        }
    }
}

/* Min width 1400px to Max width 1799px */
@media only screen and (min-width: 1400px) and (max-width: 1799px) {
    .query-advisor {
        .banner-funfect {
            position: absolute;
            bottom: 125px;
            left: 0;
        }
        .advisor-shape {
            left: 0;
        }
    }
}
</style>