<template>
    <div class="solution-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <ul class="section-style">
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                    <li>
                        How We Work
                    </li>
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                </ul>
                <h2>Affordable Solutions For All Your Creativity Needs</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6" data-aos="fade-up" data-aos-duration="1000">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt1.svg" class="fun-image" alt="image">
                        <h3>400+</h3>
                        <p>Projects Done</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6  col-sm-6 col-6" data-aos="fade-up" data-aos-duration="800">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt2.svg" class="fun-image" alt="image">
                        <h3>100+</h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6 col-6" data-aos="fade-up" data-aos-duration="1000">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt3.svg" class="fun-image" alt="image">
                        <h3>10+</h3>
                        <p>Years Experience</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6 col-6" data-aos="fade-up" data-aos-duration="800">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt4.svg" class="fun-image" alt="image">
                        <h3>30+</h3>
                        <p>Team Size</p>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/video-shape.webp" class="solvi-shape" alt="image">
    </div>
</template>

<script>
export default {
    name: 'HowWeWork'
}
</script>

<style lang="scss">
.solution-area {
    background-color: var(--headingColor);
    position: relative;

    .section-title {
        margin-left: auto;
        max-width: 680px;
        margin-right: auto;

        h2 {
            color: var(--whiteColor);
        }
    }

    .single-section {
        h2 {
            color: var(--whiteColor);
        }
    }

    .paragraph {
        p {
            color: #CCCCCC;
        }
    }

    .solvi-shape {
        position: absolute;
        top: 110px;
        right: 40px;
    }
}

.solution-section {
    margin-bottom: 20px;
}

.solution-box {
    padding: 26px 35px 35px 35px;
    background: #38394F;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin-bottom: 25px;
    padding-left: 110px;

    .fun-image {
        position: absolute;
        left: 35px;
        top: 60px;
    }

    h3 {
        font-size: 48px;
        color: var(--whiteColor);
        margin-bottom: 0;
    }

    p {
        color: #CCCCCC;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .solution-box {
        padding: 15px 10px !important;
        text-align: center;

        .fun-image {
            top: 0;
            margin: auto auto 10px;
            width: 40px;
            left: 0;
            position: relative;
        }

        h3 {
            font-size: 25px;
        }

        p {
            font-size: 12px;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .solution-box {
        padding-left: 70px;
        padding: 20px 20px 20px 70px;

        .fun-image {
            position: absolute;
            left: 20px;
            height: 35px;
            top: 38px;
        }

        h3 {
            font-size: 32px;
            margin-bottom: 0;
        }

        p {
            font-size: 13px;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .solution-box {
        padding: 20px 20px 20px 35px;
        padding-left: 85px;

        .fun-image {
            left: 20px;
            top: 45px;
        }
    }
}</style>