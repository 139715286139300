<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Log In" />
        <LogIn />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import LogIn from '../LogIn/LogIn'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'LogInPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        LogIn,
        MainFooter,
    }
}
</script>