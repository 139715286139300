<template>
    <div>
        <div class="container">
            <div class="inner-partner">
                <div class="partner-slider">
                    <carousel :autoplay="5000" :wrap-around="true" :breakpoints="breakpoints">
                        <Slide v-for="partner in partners" :key="partner.id">
                            <div class="partner-logo">
                                <img :src="partner.image" alt="logo">
                            </div>
                        </Slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'PartnerLogo',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        partners: [
            {
                id: 1,
                image: require("../../assets/images/upload/1.webp")
            },
            {
                id: 2,
                image: require("../../assets/images/upload/2.webp")
            },
            {
                id: 3,
                image: require("../../assets/images/upload/3.webp")
            },
            {
                id: 4,
                image: require("../../assets/images/upload/4.webp")
            },
            {
                id: 5,
                image: require("../../assets/images/upload/5.webp")
            },
            {
                id: 6,
                image: require("../../assets/images/upload/6.webp")
            },
            {
                id: 7,
                image: require("../../assets/images/upload/7.webp")
            },
            {
                id: 8,
                image: require("../../assets/images/upload/8.webp")
            },
            {
                id: 9,
                image: require("../../assets/images/upload/9.webp")
            },
            {
                id: 10,
                image: require("../../assets/images/upload/10.webp")
            },
            {
                id: 11,
                image: require("../../assets/images/upload/11.webp")
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
        },
    }),
})
</script>

<style lang="scss">
.partner-logo {
    margin-right: 20px;
    margin-bottom: 25px;
    position: relative;
}

.partner-logo-area {
    .carousel {
        .carousel__viewport {
            .carousel__track {
                .carousel__slide {
                    img {
                        display: block;
                        width: auto;
                        margin: auto;
                    }
                }
            }
        }
    }
}

.partner-style3 {
    .inner-partner {
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        padding: 70px 50px 45px 50px;
        position: relative;
        top: -100px;
        margin-bottom: -100px;
    }
}

.inner-partner-logo {
    border-top: solid 1px var(--borderColor);
}
</style>