<template>
    <div class="blog-area pt-100 pb-75 inner-blog">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="section-title">
                        <span>Read Our Blogs</span>
                        <h2>Popular Blog Post & News</h2>
                    </div>
                </div>
                <div class="col-lg-4">
                    <!-- <div class="input-box"> -->
                        <!-- <form> -->
                            <!-- <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search Something" aria-label="Search..." aria-describedby="button-addon2">
                                <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i class='bx bx-search'></i></button>
                            </div> -->
                        <!-- </form> -->
                    <!-- </div> -->
                </div>
            </div>
            <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">All Post</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Recent</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Popular</button>
                </li>
            </ul> -->
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <div class="row justify-content-center" >
                        <div class="col-lg-4 col-md-6"  v-for="blog in blogs"
                        :key="blog.id">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link :to="`${blog.blogDetails}`">
                                        <img :src="blog.showImage" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                           {{ blog.date }}
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comments
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link :to="`${blog.blogDetails}`">{{blog.title}} </router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link :to="`${blog.blogDetails}`" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <!-- <i class="flaticon-view"></i> -->
                                            
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent1.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Jan 22,2023
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">Are Chatbots worth the Investment?</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            4.4k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent2.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Feb 22,202
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">App or Website: Which is better to begin with?</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            2.3k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent3.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">DevOps Services</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            3.0k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent4.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">Why does design matter?About web design and current trends.</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            4.4k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent5.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">Conversion Rate the Sales Funnel Optimization</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            2.3k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent6.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">The Data-Driven Approach to Understanding</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            3.0k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent1.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                             22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">Are Chatbots worth the Investment?</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            4.4k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent2.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">App or Website: Which is better to begin with?</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            2.3k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent3.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">DevOps Services</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            3.0k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent4.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">Why does design matter?About web design and current trends.</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg"> 
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            4.4k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent5.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">Conversion Rate the Sales Funnel Optimization</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            2.3k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-card">
                                <div class="blog-image">
                                    <router-link to="/blog-details">
                                        <img src="../../assets/images/blog/recent6.webp" alt="image">
                                    </router-link>
                                </div>
                                <div class="recent-content">
                                    <ul class="blog-list">
                                        <li>
                                            <i class="flaticon-calendar"></i>
                                            Nov 22,2022
                                        </li>
                                        <!-- <li>
                                            <i class="flaticon-comment"></i>
                                            01 Comment
                                        </li> -->
                                    </ul>
                                    <h3>
                                        <router-link to="/blog-details">The Data-Driven Approach to Understanding</router-link>
                                    </h3>
                                    <ul class="recent-list">
                                        <li>
                                            <router-link to="/blog-details" class="read-more">Read More
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </li>
                                        <li>
                                            <i class="flaticon-view"></i>
                                            3.0k
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="result-pagination search-pagination">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <!-- <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">
                                    <img src="../../assets/images/svgs/slider2.svg" alt="svg">
                                </span>
                            </a>
                        </li> -->
                        <!-- <li class="page-item">
                            <a class="page-link active" href="#">1</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">
                                    <img src="../../assets/images/svgs/slider1.svg" alt="svg">
                                </span>
                            </a>
                        </li> -->
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogContent',
    data() {
        return {
            blogs: [
                {
                    id: 1,
                    showImage: require("../../assets/images/blog/recent1.webp"),
                    title: "Are Chatbots worth the Investment?",
                    blogDetails: "/blog-details?service=blog1",
                    date: " Apr 22,2023"
                },
                {
                    id: 2,
                    showImage: require("../../assets/images/blog/recent2.webp"),
                    title: "App or Website: Which is better to begin with?",
                    blogDetails: "/blog-details?service=blog2",
                    date: " May 22,2023"

                },
                {
                    id: 3,
                    showImage: require("../../assets/images/blog/recent3.webp"),
                    title: "DevOps Services",
                    blogDetails: "/blog-details?service=blog3",
                    date: " Jun 22,2023"

                },
                {
                    id: 4,
                    showImage: require("../../assets/images/blog/recent4.webp"),
                    title: "Why does design matter?About web design and current trends.",
                    blogDetails: "/blog-details?service=blog4",
                    date: " Jul 22,2023"

                },
                {
                    id: 5,
                    showImage: require("../../assets/images/blog/recent5.webp"),
                    title: "Everything about blockchain that you need to know!",
                    blogDetails: "/blog-details?service=blog5",
                    date: " Aug 22,2023"

                },
                // {
                //     id: 6,
                //     showImage: require("../../assets/images/blog/recent6.webp"),
                //     title: "IT Resources",
                //     blogDetails: "/service-details?service=it resources",
                //     date: " Nov 22,2022"

                // },
            ]
        }
    }
}
</script>

<style lang="scss">
.inner-blog {
    .recent-content {
        padding: 30px 30px !important;
        .blog-list {
            list-style: none;
            padding-left: 0;
            li {
                display: inline-block;
                position: relative;
                padding-right: 20px;
                i {
                    position: relative;
                    color: var(--hoverColor);
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
            a {
                color: var(--headingColor);
                &:hover {
                    color: var(--hoverColor);
                }
            }
        }
        .read-more {
            font-weight: 500;
            color: var(--mainColor);
            &:hover {
                color: var(--hoverColor);
            }
            i {
                font-size: 25px;
                position: relative;
                top: 8px;
            }
        }
        .recent-list {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
                display: inline-block;
                position: relative;
                padding-right: 20px;
                i {
                    position: relative;
                    color: var(--hoverColor);
                    top: 2px;
                    margin-right: 5px;
                }
                .read-more {
                    font-weight: 500;
                    color: var(--mainColor);
                    &:hover {
                        color: var(--hoverColor);
                        i {
                            color: var(--hoverColor);
                        }
                    }
                    i {
                        font-size: 25px;
                        position: relative;
                        top: 8px;
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
}
.blog-area {
    position: relative;
    z-index: 1;
}
.blog-card {
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    transition: var(--transition);
    &:hover {
        transform: translateY(-10px);
    }
    .blog-content {
        position: absolute;
        left: 40px;
        background: #FFFFFF;
        border-radius: 5px;
        right: 40px;
        bottom: 40px;
        padding: 50px;
        .blog-list {
            list-style: none;
            padding-left: 0;
            margin-bottom: 15px;
            li {
                display: inline-block;
                position: relative;
                padding-right: 20px;
                i {
                    position: relative;
                    color: var(--hoverColor);
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
        h3 {
            font-size: 22px;
            margin-bottom: 15px;
            a {
                color: var(--headingColor);
                &:hover {
                    color: var(--hoverColor);
                }
            }
        }
        .read-more {
            font-weight: 500;
            color: var(--mainColor);
            &:hover {
                color: var(--hoverColor);
                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                }
            }
            img {
                transition: var(--transition);
            }
        }
    }
    .blog-image {
        img {
            border-radius: 5px;
        }
    }
}
#myTab {
    justify-content: center;
    margin-bottom: 50px;
    border: none;
    .nav-link {
        border: none;
        font-weight: 500;
        color: var(--bodyColor);
        position: relative;
        transition: var(--transition);
        &::before {
            content: "\f106";
            font-family: flaticon !important;
            position: absolute;
            font-size: 50px;
            top: 6px;
            font-weight: 500;
            left: 0;
            right: 0;
            color: var(--mainColor);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition)
        }
        &:hover {
            border: none;
            color: var(--headingColor);
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
        &.active {
            color: var(--headingColor);
            font-weight: 700;
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.inner-blog {
    .section-title {
        text-align: left;
    }
    #myTab {
        justify-content: left;
        margin-bottom: 50px;
        border: none;
    }
    .input-box {
        position: relative;
        bottom: -125px;
        margin-top: -40px;
    }
    .blog-card {
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
        border-radius: 0px 0px 5px 5px;
    }
}
</style>