<template>
    <div class="startups-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="single-section startup-content">
                        <p class="title-tag">
                            <i class="flaticon-full-stop"></i>
                            WHY CHOOSE US
                        </p>
                        <h2>We Helps Startups In The Early Stages.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus ulla mattis pulvinar dapibus leoorem ipsum.</p>
                        <div class="choose-box choose-box-style2">
                            <div class="icon icon2">
                                <img src="../../assets/images/svgs/choose1.svg" class="choose" alt="svg">
                                <img src="../../assets/images/svgs/choose-hide1.svg" class="choose-hide" alt="svg">
                            </div>
                            <div class="content">
                                <h4>User Oriented Approach</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitv</p>
                            </div>
                        </div>
                        <div class="choose-box choose-box-style2 two">
                            <div class="icon icon2">
                                <img src="../../assets/images/svgs/choose2.svg" class="choose" alt="svg">
                                <img src="../../assets/images/svgs/choose-hide2.svg" class="choose-hide" alt="svg">
                            </div>
                            <div class="content">
                                <h4>Team Augmentation</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elitv</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="startup-form single-section">
                        <p class="title-tag">
                            <i class="flaticon-full-stop"></i>
                            ANY QUERY
                        </p>
                        <h2>Contact Us Now</h2>
                        <div class="form-area">
                            <form> 
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Name">
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Email Address">
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Phone Number">
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <input type="text" class="form-control" placeholder="Your Website">
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea  cols="30" rows="10" class="form-control" placeholder="Your Message Here"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit" class="default-btn">Submit Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>

<style lang="scss">
.startup-form {
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    margin-left: -12px;
    padding: 50px;
    .form-control {
        background: #F4F8FC;
        border-radius: 5px;
        height: 60px;
        border: none;
        padding-left: 25px;
        margin-bottom: 20px;
        color: #888888;
        &:focus {
            box-shadow: unset;
        }
        &::placeholder {
            color: #888888;
        }
    }
    textarea {
        height: 170px !important;
        padding-top: 15px;
    }
    .default-btn {
        text-align: center;
        width: 100%;
        background-color: var(--hoverColor);
        &:hover {
            background-color: var(--mainColor);
        }
    }
    h2 {
        margin-bottom: 30px;
    }
}
.choose-box-style2 {
    p {
        margin-bottom: 0;
    }
    h4 {
        margin-bottom: 8px !important;
    }
    &.two {
        margin-bottom: 0;
    }
    .icon {
        position: absolute !important;
        width: 70px;
        height: 70px;
        left: 35px;
        border-radius: 50%;
        line-height: 65px;
        text-align: center;
        margin-right: 20px;
        transition: var(--transition);
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            width: 100%;
            opacity: 0;
            border-radius: 50%;
            visibility: hidden;
            transition: var(--transition);
            background: rgba(0, 100, 251, 0.1);
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            transition: var(--transition);
            background: rgba(255, 65, 75, 0.1);
        }
        .choose-hide {
            position: absolute;
            left: 12px;
            top: 11px;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
        }
        .choose {
            transition: var(--transition);
        }
    }
    .content {
        padding-left: 90px;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .choose-content {
        margin-bottom: 30px;
        padding-left: 0;
    }
    .choose-box {
        padding: 25px 20px;
        margin-bottom: 20px;
        .content {
            h4 {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
        .icon {
            width: 50px;
            height: 50px;
            line-height: 47px;
            margin-right: 10px;
            img {
                height: 30px;
            }
        }
        &.style {
            position: relative;
            right: 0;
        }
    }
    .choose-widget {
        .choose-shape {
            display: none;
        }
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .choose-box-style2 {
        .content {
            padding-left: 65px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .choose-area {
        .choose-border {
            display: none;
        }
    }
    .choose-box.style {
        position: relative;
        right: -75px;
    }
    .choose-content {
        position: relative;
        padding-left: 0;
    }
    .choose-widget {
        margin-left: 0;
        margin-top: 40px;
        .choose-shape {
            animation-name: unset;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .choose-box {
        padding: 30px 15px;
        .content {
            h4 {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
    .choose-area {
        .choose-border {
            left: 0;
        }
    }
    .choose-widget {
        .choose-shape {
            animation-name: unset;
        }
    }
    .choose-box-style2 {
        .content {
            padding-left: 100px;
        }
        .icon {
            left: 25px;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .choose-widget {
        .choose-shape {
            animation-name: unset;
        }
    }
    .choose-area {
        .choose-border {
            left: 0;
        }
    }
    .choose-box {
        padding: 25px 20px;
        .content {
            h4 {
                font-size: 20px;
            }
        }
    }
}
</style>