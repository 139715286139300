export const project = {
    "project1": {
        title: "App or Website: Which is better to begin with?",
        about:
          "Nowadays, fashion design is becoming an increasingly hot topic among the creative because this highly competitive industry has a wide range of opportunities and scope. In this competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
          listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
          ],
            
        banner: require("../assets/images/projects/project4.webp"),
      },
      "project2": {
        title: "App or Website: Which is better to begin with?",
        about:
          "An app developed to reach the untouched parts of India with Regional learning app for K-12 students. Offering English , Math, Science and GK as the core subjects with proper impact analysis dashboards. Majorly supported by CSR and State Govt of India, this app was able to reach 50K+ students pan India",
          listOffering: [
            { id: 1, li: "Tech Stack - App Flutter for UI, Dart fot Logic, GetX for state Management" },
            { id: 2, li: "Backend - Django + Python for logic, Postgre as Database, Nginx + Gunicorn (Deployment), docker(containerization)" },
            { id: 3, li: "Frontend - HTML / CSS / JS + BootStrap + Jinja 2" },
            { id: 4, li: "Key Deliverables - A fully functional and interactive application, a platform for students to take tests and participate in competitions, UI/UX, child frindly enviornment" },
          ],
          banner: require('../assets/images/projects/project5.webp'),
      },
      "project3": {
        title: "App or Website: Which is better to begin with?",
        about:
          "ITP Media Group reaches an audience of more than 104.6 million people across the Middle East and beyond through its leading websites, portals, social media platforms, events, conferences, award ceremonies, magazines, books and social media influencers agency. ITP Media Group's state of the art offices are based in the heart of Dubai Media City. Home to their very own podcast and photography studios, recording studios, content hub, rooftop terrace, cafeteria and box office; ITP Media Group produces and delivers a broad range of content options in-house.",
          listOffering: [
            { id: 1, li: "Tech Stack - JavaScript, HTML5, " },
            { id: 2, li: "Key Deliverables - Provide interactive animations for various website landing pages. UI/UX" },
            { id: 3, li: "Key Features - Highlight and interactive website which looking aesthetically pleasing and minimalistic. Multiple landing pages depicting live numbers related to the information provided. x" },

          ],
          banner: require('../assets/images/projects/project6.webp'),
      },
      "project4": {
        title: "App or Website: Which is better to begin with?",
        about:
          "Growpital opens up tax free passive earning avenues for you while increasing income and productivity of agriculture sector. Investments in Alternative asset classes was previously reserved only for institutions and the ultra-wealthy. Sub-segmenting it to Agriculture, it is pretty niche and popular as an investment class. Growpital wants to provide opportunity to own fraction of Agriculture asset classes and participate in passive income generation for yourself that were typically off-limits. Think of us a fund, but for agri projects where the allocation is done on the basis of parameters which fits to agriculture like crop tenure, harvest cycles, insurance, Government MSP, crop segment, crop type etc.",      
          banner: require('../assets/images/projects/project1.webp'),
      },
      "project5": {
        title: "App or Website: Which is better to begin with?",
        about:
          " EastMojo is a digital news media platform promoting north east India news. Led by a team of renowned journalists, EastMojo covers all the news from the 8 north-eastern states that are Arunachal news, Assam news, Manipur news, Meghalaya news, Mizoram news, Nagaland news, Sikkim news and Tripura news. The focus is always on bringing to the forefront Assam’s latest news, news live from ground zero, breaking news of Northeast, Assam news headlines, and quality stories that reflect the culture and ways of life of the people in the region.",        
          banner: require('../assets/images/projects/project3.webp'),
      },
      "project6": {
        title: "App or Website: Which is better to begin with?",
        about:
          "BitMemoir is a web3 based application that enables individuals and organisations to provide authorised clients with digital certifications. Individual users can convert personal identifiers,property documents, and other sensitive materials into digital certificates, or NFTs, and store them in their own wallets. By utilising blockchain technology, BitMemoir enables users to own their personal documents in perpetuity.",
          banner: require('../assets/images/projects/project2.webp'),
      },

    }
  