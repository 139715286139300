<template>
    <div class="newsletter-area">
        <div class="container">
            <div class="inner-newsletter">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="news-content">
                            <span>Subscribe Newsletter</span>
                            <h2>Signup Our Newsletter</h2>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="newsletter-input">
                            <form @submit="handleSubmit" class="newsletter-form" data-toggle="validator">
                                <input type="email" placeholder="Enter Your Email Address" id="name3" class="form-control"
                                    v-model="email" required>
                                <button class="default-btn " type="submit" style="pointer-events: all; cursor: pointer;">
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <img src="../../assets/images/shapes/news-letter.webp" class="news-shape" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            email: '',
        };
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault(); // Prevent form submission
            const data = {
                email: this.email,
            };

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            axios.post('https://node-bitsmart.onrender.com/v0/subscribenewslatter', data, config)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });

            // Reset form fields
            this.email = '';

        },

    },
    name: 'SubscribeNewsletter'
}
</script>

<style lang="scss">
.newsletter-area {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        width: 100%;
        background-color: var(--headingColor);
    }
}

.inner-newsletter {
    background-color: var(--hoverColor);
    border-radius: 5px;
    padding: 100px 90px;
    position: relative;
    z-index: 1;

    .news-shape {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }
}

.news-content {
    span {
        font-weight: 500;
        color: var(--whiteColor);
        margin-bottom: 10px;
        display: inline-block;
    }

    h2 {
        font-size: 48px;
        color: var(--whiteColor);
        margin-bottom: 0;
        letter-spacing: -0.03em;
    }
}

.newsletter-input {
    margin-left: 60px;

    .newsletter-form {
        position: relative;

        .form-control {
            position: relative;
            height: 70px;
            background-color: var(--whiteColor);
            border-radius: 5px;
            padding-left: 25px;

            &:focus {
                box-shadow: unset;

                &::placeholder {
                    color: transparent;
                    transition: var(--transition);
                }
            }
        }

        .default-btn {
            position: absolute;
            top: 7px;
            padding: 10px 28px;
            right: 7px;
            bottom: 7px;
            line-height: 0;
            font-size: 32px;

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
                transition: var(--transition);
            }
        }

        #validator-newsletter {
            position: absolute;
            top: 80px;
            color: #fff;
        }
    }
}

.newsletter-area3 {
    position: relative;
    z-index: 1;

    &::before {
        display: none;
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        height: 50%;
        width: 100%;
        background-color: var(--headingColor);
    }
}
</style>