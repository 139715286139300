<template>
    <div class="map-area">
        <div class="map">
            <div class="location-map">
                <iframe
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Plat No. 25-B, Magnet Coworks, Sector 31, Gurugram, Haryana, 122001&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapContent'
}
</script>

<style lang="scss">
.map-area {
    iframe {
        height: 500px;
        width: 100%;
        border: none;
        margin-bottom: -10px;
    }
}
</style>

<style>
.mapouter {
    position: relative;
    text-align: right;
    width: 600px;
    height: 400px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 600px;
    height: 400px;
}

.gmap_iframe {
    width: 600px !important;
    height: 400px !important;
}
</style>
