<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span>Get in Touch</span>
                <h2>Don't hesitate to Send a Message</h2>
            </div>
            <div class="contact-widget">
                <div class="inner-massage">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="massage-box">
                                <div class="icon">
                                    <i class="flaticon-pin"></i>
                                </div>
                                <div class="content">
                                    <h5>Our Address</h5>
                                    <p>Plat No. 25-B, Magnet Coworks, Sector 31, Gurugram, Haryana, 122001</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="massage-box">
                                <div class="icon">
                                    <i class="flaticon-phone-call-1"></i>
                                </div>
                                <div class="content">
                                    <h5>Our Phone</h5>
                                    <p>
                                        <a href="tel:3128959800">(91) 73032-05771</a>
                                        <!-- <a href="tel:01234567890">012 345 67 890</a> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="massage-box">
                                <div class="icon">
                                    <i class="flaticon-email"></i>
                                </div>
                                <div class="content">
                                    <h5>Our Mail</h5>
                                    <p>
                                        <a href="mailto:hello@vnet.com">contact@beingbitsmart.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contact-form-area">
                    <form @submit="handleSubmit" class="contact-from">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" id="name1" class="form-control" placeholder="Your Name"
                                        v-model="name">
                                    <p v-if="nameError" class="error-message">Please enter your name</p>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="email" id="name3" class="form-control" placeholder="Your Email"
                                        v-model="email">
                                    <p v-if="emailError" class="error-message">Please enter a valid email address</p>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="number" id="name1" class="form-control" placeholder="Your Phone"
                                        v-model="mobile">
                                    <p v-if="mobileError" class="error-message">Please enter a valid mobile number</p>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" id="name1" class="form-control" placeholder="Your Subject"
                                        v-model="subject">
                                    <p v-if="subjectError" class="error-message">Please enter a subject</p>
                                </div>

                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                        placeholder="Your Message" v-model="message"></textarea>
                                    <p v-if="messageError" class="error-message">Please enter your message</p>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="submit-btn text-center">
                                    <button type="submit" class="default-btn">Send Your Messgae</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            name: '',
            email: '',
            message: '',
            subject: '',
            mobile: null,
            subjectError: false,
            mobileError: false,
            nameError: false,
            emailError: false,
            messageError: false
        };
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault(); // Prevent form submission
            this.nameError = false;
            this.emailError = false;
            this.messageError = false;
            this.subjectError = false;
            this.mobileError = false
            // Perform form validation
            if (!this.name) {
                this.nameError = true;
            }
            if (!this.email || !this.isValidEmail(this.email)) {
                this.emailError = true;
            }
            if (!this.message) {
                this.messageError = true;
            }
            if (!this.mobile) {
                this.mobileError = true;
            }
            if (!this.subject) {
                this.subjectError = true;
            }

            // Proceed with API request if form is valid
            if (!this.nameError && !this.emailError && !this.messageError && !this.mobileError && !this.subjectError) {
                const data = {
                    email: this.email,
                    name: this.name,
                    message: this.message,
                    mobile: this.mobile,
                    subject: this.subject
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                axios.post('https://node-bitsmart.onrender.com/v0/sendEmailNotification', data, config)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                    });

                // Reset form fields
                this.name = '';
                this.email = '';
                this.message = '';
                this.subject = '';
                this.mobile = null
            }
        },
        isValidEmail(email) {
            // Basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }
    },
    name: 'ContactContent'
}
</script>

<style lang="scss">
.error-message {
    color: red;
    font-size: 12px;

}

.form-group {
    margin-bottom: 20px;
}

.inner-massage {
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    margin-bottom: 40px;
    padding-top: 50px;
    padding-bottom: 60px;
}

.massage-box {
    padding: 0px 45px 0px 45px;
    text-align: center;
    border-right: solid 1px var(--borderColor);

    .icon {
        height: 80px;
        width: 80px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        line-height: 90px;
        border-radius: 50%;
        font-size: 30px;
        margin-bottom: 20px;
        transition: var(--transition);
        color: var(--hoverColor);
        background: rgba(255, 65, 75, 0.1);

        &:hover {
            background-color: var(--hoverColor);
            color: var(--whiteColor);
        }
    }

    .content {
        h5 {
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 10px;
        }
    }
}

.col-lg-4 {
    &:nth-child(3) {
        .massage-box {
            border: none;
        }
    }
}

.contact-widget {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.contact-form-area {
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
    padding: 50px 30px;

    .form-control {
        background: #F4F8FC;
        border-radius: 5px;
        height: 60px;
        padding-left: 18px;
        color: #888888;
        border: none;

        &:focus {
            box-shadow: unset;
        }

        &::placeholder {
            color: #888888;
        }
    }

    textarea.form-control {
        min-height: 180px;
        padding-top: 18px;
    }
}
</style>