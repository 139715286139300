<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <MainBanner />
        <PartnerLogo class="partner-logo-area pt-100 pb-75" />
        <AboutOurCompany />
        <ServicesWeOffer />
        <WhyChooseUs />
        <HowWeWork />
        <VideoContent />
        <OurCaseStudy />
        <!-- <ChooseYourBestPlans /> -->
        <OurTeamMembers />
        <OurClientTestimonial />
        <ContactUs />
        <BlogPost />
        <SubscribeNewsletter />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import MainBanner from '../HomeTwo/MainBanner'
import PartnerLogo from '../Common/PartnerLogo'
import AboutOurCompany from '../HomeTwo/AboutOurCompany'
import ServicesWeOffer from '../HomeTwo/ServicesWeOffer'
import WhyChooseUs from '../HomeTwo/WhyChooseUs'
import HowWeWork from '../HomeTwo/HowWeWork'
import VideoContent from '../HomeTwo/VideoContent'
import OurCaseStudy from '../HomeTwo/OurCaseStudy'
// import ChooseYourBestPlans from '../Common/ChooseYourBestPlans'
import OurTeamMembers from '../HomeTwo/OurTeamMembers'
import OurClientTestimonial from '../HomeTwo/OurClientTestimonial'
import ContactUs from '../HomeTwo/ContactUs'
import BlogPost from '../Common/BlogPost'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'HomeTwoPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        MainBanner,
        PartnerLogo,
        AboutOurCompany,
        ServicesWeOffer,
        WhyChooseUs,
        HowWeWork,
        VideoContent,
        OurCaseStudy,
        // ChooseYourBestPlans,
        OurTeamMembers,
        OurClientTestimonial,
        ContactUs,
        BlogPost,
        SubscribeNewsletter,
        MainFooter,
    }
}
</script>