<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="About Us" />
        <AboutOurCompany />
        <PartnerLogo class="partner-logo-area pt-100 pb-75 bg-F4F8FC" />
        <HowWeWork />
        <WhyChooseUs />
        <OurClientTestimonial />
        <ContactUs />
        <FunFect class="pt-100" />
        <SubscribeNewsletter />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import AboutOurCompany from '../AboutTwo/AboutOurCompany'
import PartnerLogo from '../Common/PartnerLogo'
import HowWeWork from '../AboutTwo/HowWeWork'
import WhyChooseUs from '../AboutTwo/WhyChooseUs'
import OurClientTestimonial from '../AboutTwo/OurClientTestimonial'
import ContactUs from '../AboutTwo/ContactUs'
import FunFect from '../Common/FunFect'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'AboutTwoPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        AboutOurCompany,
        PartnerLogo,
        HowWeWork,
        WhyChooseUs,
        OurClientTestimonial,
        ContactUs,
        FunFect,
        SubscribeNewsletter,
        MainFooter,
    }
}
</script>