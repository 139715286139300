<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Team Members" />
        <TeamMembers />
        <PartnerLogo class="partner-logo-area pt-100 pb-75 inner-partner-logo" />
        <SubscribeNewsletter />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import TeamMembers from '../Team/TeamMembers'
import PartnerLogo from '../Common/PartnerLogo'
import SubscribeNewsletter from '../Common/SubscribeNewsletter'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'TeamPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        TeamMembers,
        PartnerLogo,
        SubscribeNewsletter,
        MainFooter,
    }
}
</script>