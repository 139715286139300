<template>
    <div class="project-area pb-75 pt-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="project-content single-section">
                        <span>Our Case Study</span>
                        <h2>Our Realizations & Recent Cases</h2>
                        <p>here are a few recent projects which the team has worked on, giving exceptional results and happy
                            partners.</p>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="custom-navigation">
                                    <button class="carousel-prev" @click="prev"><img
                                            src="../../assets/images/svgs/slider2.svg" alt="svg"></button>
                                    <button class="carousel-next" @click="next"><img
                                            src="../../assets/images/svgs/slider1.svg" alt="svg"></button>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="project-link">
                                    <router-link to="/case-study">See All Projects
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="project-content-shape" alt="image">
                    </div>
                </div>

                <div class="col-lg-9">
                    <div class="project-big-widget">
                        <div class="project-slider">
                            <carousel :autoplay="5000" :wrap-around="true" :breakpoints="breakpoints" ref="carousel">
                                <Slide v-for="project in projects" :key="project.id">
                                    <div class="project-box">
                                        <div class="image">
                                            <img :src="project.image" alt="image">
                                        </div>
                                        <div class="content">
                                            <h4>
                                                <router-link to="/case-study-details">{{ project.title }}</router-link>
                                            </h4>
                                            <p>{{ project.desc }}</p>
                                        </div>
                                        <img src="../../assets/images/shapes/project-shape.webp" class="project-shape"
                                            alt="image">
                                    </div>
                                </Slide>
                            </carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'OurCaseStudy',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        projects: [
            {
                id: 1,
                image: require("../../assets/images/projects/project1.webp"),
                title: 'Growpital',
                desc: 'Agro Based Investment',
            },
            {
                id: 2,
                image: require("../../assets/images/projects/project2.webp"),
                title: 'BitMemoir',
                desc: 'BloackChain Certifications',
            },
            {
                id: 3,
                image: require("../../assets/images/projects/project3.webp"),
                title: 'EastMojo',
                desc: 'News App',
            },
            {
                id: 4,
                image: require("../../assets/images/projects/project4.webp"),
                title: 'Khawishh',
                desc: 'Fashion Store',
            },
            {
                id: 5,
                image: require("../../assets/images/projects/project5.webp"),
                title: 'BrainWoo',
                desc: 'Education Platform',
            },
            {
                id: 6,
                image: require("../../assets/images/projects/project6.webp"),
                title: 'ITP Media',
                desc: 'Media Company',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
    methods: {
        next() {
            this.$refs.carousel.next()
        },
        prev() {
            this.$refs.carousel.prev()
        },
    }
})
</script>

<style lang="scss">
.project-area {
    overflow: hidden;

    .container-fluid {
        max-width: 1620px;
        margin-right: 0;
        margin-left: auto;
    }
}

.project-big-widget {
    position: relative;
    right: -60px;
    padding-left: 25px;

    .project-slider {
        .carousel {
            text-align: left;

            .carousel__viewport {
                .carousel__slide {
                    padding: 0 10px;
                }
            }
        }
    }
}

.project-box {
    position: relative;
    margin-bottom: 25px;
    z-index: 1;

    .content {
        position: absolute;
        bottom: 30px;
        padding-left: 30px;
        z-index: 9;

        h4 {
            font-size: 22px;

            a {
                color: var(--whiteColor);
                z-index: 2;

                &:hover {
                    color: var(--hoverColor);
                }
            }
        }

        p {
            color: var(--whiteColor);
            margin-bottom: 0;
        }
    }

    .image {
        img {
            border-radius: 5px;
        }
    }

    .project-shape {
        position: absolute;
        z-index: 2;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
    }

    &:hover {
        .project-shape {
            opacity: 1;
            visibility: visible;
        }
    }
}

.project-content {
    position: relative;
    margin-right: -45px;

    p {
        margin-bottom: 30px;
    }

    .project-content-shape {
        position: absolute;
        top: -65px;
        left: 65%;
        -webkit-animation-name: rotateme;
        animation-name: rotateme;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
}

.project-link {
    text-align: end;

    a {
        font-weight: 500;
        color: var(--mainColor);

        &:hover {
            color: var(--hoverColor);

            img {
                filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
            }
        }

        img {
            transition: var(--transition);
        }
    }
}
</style>
