<template>
    <div>
        <div class="video-area">
            <div class="container">
                <div class="inner-area">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="single-section video-content">
                                <span>How We Work</span>
                                <h2>Affordable Solutions For All Your Creativity Needs</h2>
                                <div class="button-link d-flex align-items-center">
                                    <div 
                                        class="popup-youtube"
                                        v-on:click="isPopupMethod(isPopup)"
                                        style="cursor: pointer"
                                    >
                                        <div class="icon-play">
                                            <i class="flaticon-play-1"></i>
                                        </div>
                                    </div>
                                    <router-link to="/case-study" class="case-tap">See All Case Studies
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/9dE0DkGcPlk" title="Webtecknow - Affordable Solutions for all your Creativity needs" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowWeWork',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>

<style lang="scss">
.inner-area {
    background-image: url(../../assets/images/video/video-bg.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 85px;
    position: relative;
    padding-top: 180px;
    padding-bottom: 180px;
    z-index: 1;
    border-radius: 15px;
}
.video-content {
    h2 {
        color: var(--whiteColor);
    }
    .popup-youtube {
        width: 70px;
        height: 70px;
        background-color: #FF414B;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        margin: 20px 50px 20px 20px;
        position: relative;
        line-height: 77px;
        font-size: 22px;
        &:hover {
            background-color: var(--mainColor);
        }
        &::before {
            content: '';
            position: absolute;
            top: -20px;
            left: -20px;
            right: -20px;
            bottom: -20px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            z-index: -1;
            -webkit-animation: ripple 5s linear infinite;
            animation: ripple 5s linear infinite;
        }
    }
    @keyframes ripple {
        0% {
            transform: scale(1);
        }
        75% {
            transform: scale(1.5);
            opacity: .6;
        }
        100% {
            transform: scale(2);
            opacity: 0;
        }
    }
    .case-tap {
        color: var(--whiteColor);
        font-weight: 500;
        &:hover {
            color: var(--hoverColor);
            img {
                filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
            }
        }
        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
            transition: var(--transition);
        }
    }
}
.video-area {
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 45%;
        width: 100%;
        z-index: -1;
        background: #F4F8FC;
    }
}

@media only screen and (max-width: 767px) {
    .inner-area {
        padding: 60px 20px;
    }
    .video-content {
        .popup-youtube {
            width: 45px;
            height: 45px;
            margin: 12px 20px 20px 12px;
            line-height: 50px;
            font-size: 12px;
            &::before {
                top: -5px;
                left: -5px;
                right: -5px;
                bottom: -5px;
            }
        }
    }
    .button-link {
        align-items: baseline !important;
    }
}
</style>