<template>
    <div class="testimonial-area ptb-100 bg-2E2F46 ">
        <div class="container">
            <div class="single-section">
                <span>Our Client Testimonial</span>
                <h2>Loved By Thousand Clients</h2>
            </div>
            <div class="row">
                <div class="col-lg-5">
                    <div class="testimonial-user">
                        <div class="client-box">
                            <h2>400+</h2>
                            <p>Made Available Our Client</p>
                        </div>
                        <div class="testimonial-client">
                            <ul>
                                <li>
                                    <img src="../../assets/images/clients/client1.webp" class="client-1" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client2.webp" class="client-2" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client3.webp" class="client-3" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client4.webp" class="client-4" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client5.webp" class="client-5" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client6.webp" class="client-6" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client7.webp" class="client-7" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client8.webp" class="client-8" alt="image">
                                </li>
                                <li>
                                    <img src="../../assets/images/clients/client9.webp" class="client-9" alt="image">
                                </li>
                            </ul>
                            <div class="all-ball">
                                <img src="../../assets/images/clients/ball1.webp" class="ball-1" alt="image">
                                <img src="../../assets/images/clients/ball2.webp" class="ball-2" alt="image">
                                <img src="../../assets/images/clients/ball3.webp" class="ball-3" alt="image">
                                <img src="../../assets/images/clients/ball4.webp" class="ball-4" alt="image">
                                <img src="../../assets/images/clients/ball5.webp" class="ball-5" alt="image">
                            </div>
                        </div>
                        <div class="mobile-image">
                            <img src="../../assets/images/clients/all-client.webp" class="mobile-image" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="test-slide-widget">
                        <div class="testimonial-slider">
                            <carousel
                                :autoplay="5000"
                                :wrap-around="true"
                                ref="carousel"
                            >
                                <Slide 
                                    v-for="testimonial in testimonials"
                                    :key="testimonial.id"
                                >
                                    <div class="testimonial-content">
                                        <div class="testimonial-self d-flex">
                                            <div class="image">
                                                <img :src="testimonial.image" alt="image">
                                            </div>
                                            <div class="content">
                                                <h3>{{testimonial.name}}</h3>
                                                <p>{{testimonial.position}}</p>
                                            </div>
                                        </div>
                                        <p class="main-p ">{{testimonial.desc}}</p>
                                        <div class="icon">
                                            <img src="../../assets/images/svgs/quent-1.svg" alt="image">
                                        </div>
                                        <div class="star">
                                            <ul>
                                                <li>
                                                    <i class='bx bxs-star'></i>
                                                </li>
                                                <li>
                                                    <i class='bx bxs-star'></i>
                                                </li>
                                                <li>
                                                    <i class='bx bxs-star'></i>
                                                </li>
                                                <li>
                                                    <i class='bx bxs-star'></i>
                                                </li>
                                                <li>
                                                    <i class='bx bxs-star'></i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </carousel>

                            <div class="custom-navigation">
                                <button class="carousel-prev" @click="prev"><img src="../../assets/images/svgs/slider2.svg" alt="svg"></button>
                                <button class="carousel-next" @click="next"><img src="../../assets/images/svgs/slider1.svg" alt="svg"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/testimonial-shape.webp" class="testimonial-shape" alt="image">
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'OurClientTestimonial',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        testimonials: [
            {
                id: 1,
                image: require("../../assets/images/clients/t-client1.webp"),
                name: 'Jonathan Santiago',
                position: 'Product Manager',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit telluslu ctus ullam corper mattis pulvinar dapibus leorem vnar ipsum dolor sitam consectetur adipiscing elitut elit tellu luctus pulvinar dapibus leolorem ipsum dolor sit amet, consectetur adipiscing elit.',
            },
            {
                id: 2,
                image: require("../../assets/images/clients/t-client1.webp"),
                name: 'Jonathan Santiago',
                position: 'Product Manager',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit telluslu ctus ullam corper mattis pulvinar dapibus leorem vnar ipsum dolor sitam consectetur adipiscing elitut elit tellu luctus pulvinar dapibus leolorem ipsum dolor sit amet, consectetur adipiscing elit.',
            },
        ],
    }),
    methods: {
        next() {
            this.$refs.carousel.next()
        },
        prev() {
            this.$refs.carousel.prev()
        },
    }
})
</script>

<style lang="scss">
.testimonial-client {
    position: relative;
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
           display: inline-block;
           padding-left: 10px;
           position: relative;
           padding-right: 10px; 
           img {
            border-radius: 50%;
           }
           &:nth-child(1) {
                position: relative;
                top: -174px;
                left: 462px;
           }
           &:nth-child(2) {
                position: relative;
                top: -100px;
                left: 315px;
           }
            &:nth-child(4) {
                position: relative;
                left: 180px;
                top: -10px;
            }
            &:nth-child(6) {
                position: relative;
                left: 400px;
                top: -10px;
            }
            &:nth-child(7) {
                position: relative;
                left: 382px;
                top: 78px;
            }
            &:nth-child(3) {
                position: relative;
                top: -80px;
                left: 95px;
            }
            &:nth-child(5) {
                position: relative;
                top: 48px;
                left: -190px;
            }
            &:nth-child(9) {
                top: 60px;
                position: relative;
                left: -263px;
            }
            &:nth-child(8) {
                top: 75px;
                position: relative;
                left: 32px;
            }
            .user-content {
                width: 330px;
                padding: 30px;
                background-color: #ffffff;
                text-align: left;
                border-radius: 10px;
                position: absolute;
                top: 10px;
                left: 100px;
                opacity: 0;
                visibility: hidden;
                transition: all ease 0.5s;
                z-index: 9;
                &::before {
                    position: absolute;
                    content: '';
                    height: 50px;
                    width: 35px;
                    border-left: 15px solid #ffffff;
                    border-right: 15px solid #ffffff;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    left: -10px;
                    top: 15px;
                    -webkit-transform: rotate(-20deg);
                    transform: rotate(-20deg);
                }
                .rating-list {
                    margin-bottom: 8px;
                    i {
                        color: var(--starColor);
                    }
                }
                p {
                    font-size: 14px;
                    margin-bottom: 12px;
                }
                h3 {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                span {
                    font-size: 14px;
                }
            }
            &:hover {
                .user-content {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    .ball-1 {
        position: absolute;
        top: -155px;
        right: 98px;
        -webkit-animation-name: rotateme;
        animation-name: rotateme;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    .ball-2 {
        position: absolute;
        top: 15px;
        right: 80px;
        -webkit-animation: ripple 9s linear infinite;
        animation: ripple 9s linear infinite;
    }
    .ball-3 {
        position: absolute;
        top: 60px;
        right: 150px;
        -webkit-animation-name: rotateme;
        animation-name: rotateme;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    .ball-4 {
        position: absolute;
        bottom: -47px;
        left: 327px;
        -webkit-animation-name: rotateme;
        animation-name: rotateme;
        -webkit-animation-duration: 20s;
        animation-duration: 20s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    .ball-5 {
        position: absolute;
        left: 137px;
        bottom: -52px;
        -webkit-animation: ripple 9s linear infinite;
        animation: ripple 9s linear infinite;
    }
}
.testimonial-area{
    position: relative;
    z-index: 1;
    .single-section {
        margin-bottom: 50px;
        h2 {
            color: var(--whiteColor);
        }
    }
    .testimonial-shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
}
.client-box {
    background: #36374D;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 7%);
    border-radius: 5px;
    padding: 35px 40px;
    text-align: center;
    display: inline-block;
    top: 55px;
    position: relative;
    h2 {
        color: var(--whiteColor);
        font-size: 40px;
        margin-bottom: 0;
    }
    p {
        color: #CCCCCC;
    }
}
.testimonial-content {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    padding: 45px 45px 50px 45px;
    .testimonial-self {
        align-items: center;
        margin-bottom: 30px;
        .image {
            margin-right: 20px;
            img {
                width: 100px;
                border-radius: 50%;
            }
        }
        .content {
            h3 {
                font-size: 22px;
                color: var(--whiteColor);
                margin-bottom: 5px;
            }
            p {
                color: #CCCCCC;
                margin-bottom: 0;
            }
        }
    }
    .main-p {
        color: #FFFFFF;
        margin-bottom: 20px;
        font-style: italic;
        font-weight: 500;
        font-size: 18px;
    }
    .star {
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                padding-right: 5px;
                i {
                    color: var(--starColor);
                    margin-right: 3px;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    .icon {
        position: absolute;
        top: 21%;
        right: 10%;
        i {
            font-size: 40px;
            color: var(--whiteColor);
        }
    }
}
.test-slide-widget {
    position: relative;
    margin-left: 50px;
    
    .testimonial-slider {
        .custom-navigation {
            position: absolute;
            right: -5px;
            top: -113px;
            .carousel-prev, .carousel-next {
                background: #38394F !important;
            }
        }
        .carousel {
            text-align: left;
        }
    }
}
.mobile-image {
    display: none;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .testimonial-client {
        display: none;
    }
        .testimonial-area {
        .single-section {
            margin-bottom: 15px;
        }
    }
    .test-slide-widget {
        margin-left: 0;
    }
    .client-box {
        top: 0;
        position: relative;
        margin-bottom: -50px;
        padding: 20px 15px;
    }
    .testimonial-content {
        padding: 30px 20px 30px 20px;
        .testimonial-self {
            margin-bottom: 20px;
            .image {
                margin-right: 20px;
                img {
                    width: 60px;
                }
            }
            .content {
                h3 {
                    font-size: 15px;
                    margin-bottom: 5px;
                }
            }
        }
        .icon {
            position: absolute;
            top: 11%;
            right: 5%;
            i {
                font-size: 25px;
            }
        }
        .main-p {
            font-size: 14px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial-client {
        position: relative;
        display: none;
    }
    .testimonial-area {
        .single-section {
            margin-bottom: 30px;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial-client {
        display: none;
    }
    .testimonial-content {
        padding: 35px 25px 35px 35px;
        .testimonial-self {
            margin-bottom: 20px;
            .image {
                img {
                    width: 60px;
                }
            }
            .content {
                h3 {
                    font-size: 18px;
                    margin-bottom: 8px;
                }
            }
        }
        .main-p {
            margin-bottom: 15px;
            font-size: 16px;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .testimonial-client {
        ul {
            li {
                &:nth-child(1) {
                    left: 424px;
                }
                &:nth-child(3) {
                    left: 87px;
                }
                &:nth-child(2) {
                    top: -82px;
                    left: 305px;
                }
                &:nth-child(4) {
                    left: 134px;
                    top: 2px;
                }
                &:nth-child(6) {
                    position: relative;
                    left: 290px;
                    top: 24px;
                }
                &:nth-child(5) {
                    position: relative;
                    top: -35px;
                    left: 80px;
                }
                &:nth-child(7) {
                    left: 5px;
                    top: -5px;
                }
                &:nth-child(9) {
                    top: 22px;
                    position: relative;
                    left: 0;
                }
            }
        }
        .ball-3 {
            position: absolute;
            top: 165px;
            right: 252px;
        }
        .ball-5 {
            display: none;
        }
        .ball-2 {
            position: absolute;
            top: 69px;
            right: 80px;
        }
        .ball-4 {
            display: none;
        }
    }
}
</style>