<template>
    <div class="query-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 p-0">
                    <div class="query-image">
                    </div>
                </div>
                <div class="col-lg-7 p-0">
                    <div class="query-form single-section">
                        <span>Any Query?</span>
                        <h2>Contact Us Now</h2>
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Email Address">
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Phone Number">
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <input type="text" class="form-control" placeholder="Your Website">
                                </div>
                                <div class="col-lg-12">
                                    <textarea  cols="30" rows="10" class="form-control" placeholder="Your Message Here"></textarea>
                                </div>
                                <div class="col-lg-12">
                                    <button type="submit" class="default-btn">Submit Now</button>
                                </div>
                            </div>
                        </form>
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="project-content-shape" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs'
}
</script>

<style lang="scss">
.query-image {
    background-image: url(../../assets/images/query/query-bg1.webp);
    background-position: center center ;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px 0px 0px 5px;
}
.query-form {
    padding: 100px;
    background: #F4F8FC;
    position: relative;
    border-radius: 0px 5px 5px 0px;
    .form-control {
        background: #FFFFFF;
        border-radius: 5px;
        height: 60px;
        border: none;
        padding-left: 20px;
        margin-bottom: 20px;
        color: #888888;
        &:focus {
            box-shadow: unset;
            &::placeholder {
                color: transparent;
                transition: var(--transition);
            }
        }
        &::placeholder {
            color: #888888;
        }
    }
    textarea {
        height: 180px !important;
        padding-top: 20px;
    }
    .default-btn {
        text-align: center;
        width: 100%;
    }
    .project-content-shape {
        position: absolute;
        top: 59%;
        left: 20px;
    }
}
.query-area {
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 55%;
        width: 100%;
        z-index: -1;
        background: #2E2F46;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .query-form {
        padding: 60px 20px;
        border-radius: 0;
        .form-control {
            height: 45px;
            font-size: 14px;
        }
        .project-content-shape {
            display: none;
        }
    }
    .query-image {
        height: 400px;
        border-radius: 0;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .query-form {
        padding: 80px 30px;
        .project-content-shape {
            display: none;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .query-form {
        padding: 30px;
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .query-form {
        padding: 65px 50px;
    }
}
</style>