<template>
    <div class="solution-area pt-100 pb-75">
        <div class="container">
            <div class="solution-section">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="single-section">
                            <p class="title-tag">
                                <i class="flaticon-full-stop"></i>
                                OUR FUN FACT
                            </p>
                            <h2>Affordable solutions for all your creativity needs</h2>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="paragraph">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus  ullamcorper mattis pulvinar dapibus leoorem ipsum dolor sit amet cons adipiscing elitut tellu luctus pulvinar dapibus leo.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt1.svg" class="fun-image" alt="image">
                        <h3>12k</h3>
                        <p>Projects Done</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6  col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400" data-aos-once="true">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt2.svg" class="fun-image" alt="image">
                        <h3>165+</h3>
                        <p>Satisfied Clients</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600" data-aos-once="true">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt3.svg" class="fun-image" alt="image">
                        <h3>26+</h3>
                        <p>Awards Winner</p>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="800" data-aos-once="true">
                    <div class="solution-box">
                        <img src="../../assets/images/svgs/solt4.svg" class="fun-image" alt="image">
                        <h3>12k</h3>
                        <p>Years of Experience</p>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/video-shape.webp" class="solvi-shape" alt="image">
    </div>
</template>

<script>
export default {
    name: 'OurFunFect'
}
</script>


<style lang="scss">
.solution-area {
    background-color: var(--headingColor);
    position: relative;
    .section-title {
        margin-left: auto;
        max-width: 680px;
        margin-right: auto;
        h2 {
            color: var(--whiteColor);
        }
    }
    .single-section {
        h2 {
            color: var(--whiteColor);
        }
    }
    .paragraph {
        p {
            color: #CCCCCC;
        }
    }
    .solvi-shape {
        position: absolute;
        top: 110px;
        right: 40px;
    }
}
.solution-section {
    margin-bottom: 20px;
}
.solution-box {
    padding: 26px 35px 35px 35px;
    background: #38394F;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin-bottom: 25px;
    padding-left: 110px;
    .fun-image {
        position: absolute;
        left: 35px;
        top: 60px;
    }
    h3 {
        font-size: 48px;
        color: var(--whiteColor);
        margin-bottom: 0;
    }
    p {
        color: #CCCCCC;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .solution-box {
        padding: 15px 10px!important;
        text-align: center;
        .fun-image {
            top: 0;
            margin: auto auto 10px;
            width: 40px;
            left: 0;
            position: relative;
        }
        h3 {
            font-size: 25px;
        }
        p {
            font-size: 12px;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .solution-box {
        padding-left: 70px;
        padding: 20px 20px 20px 70px;
        .fun-image {
            position: absolute;
            left: 20px;
            height: 35px;
            top: 38px;
        }
        h3 {
            font-size: 32px;
            margin-bottom: 0;
        }
        p {
            font-size: 13px;
        }
    }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .solution-box {
        padding: 20px 20px 20px 35px;
        padding-left: 85px;
        .fun-image {
            left: 20px;
            top: 45px;
        }
    }
}
</style>