<template>
    <div>
        <div class="video-area-style2 pb-100">
            <div class="container">
                <div class="inner-video">
                    <div class="button-btn">
                        <div 
                            class="popup-youtube"
                            v-on:click="isPopupMethod(isPopup)"
                            style="cursor: pointer"
                        >
                            <div class="icon-play">
                                <i class="flaticon-play-1"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/9dE0DkGcPlk" title="Webtecknow - Affordable Solutions for all your Creativity needs" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoContent',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>

<style lang="scss">
.inner-video {
    background-image: url(../../assets/images/video/video-bg2.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 265px;
    padding-bottom: 265px;
    z-index: 1;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    transition: var(--transition);
    .popup-youtube {
        width: 70px;
        height: 70px;
        background-color: #FF414B;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        display: inline-block;
        position: relative;
        line-height: 77px;
        font-size: 22px;
        &:hover {
            background-color: var(--mainColor);
        }
        &::before {
            content: '';
            position: absolute;
            top: -20px;
            left: -20px;
            right: -20px;
            bottom: -20px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            z-index: -1;
            -webkit-animation: ripple 5s linear infinite;
            animation: ripple 5s linear infinite;
        }
    }
    @keyframes ripple {
        0% {
            transform: scale(1);
        }
        75% {
            transform: scale(1.5);
            opacity: .6;
        }
        100% {
            transform: scale(2);
            opacity: 0;
        }
    }
}
.video-area-style2 {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 46%;
        width: 100%;
        background-color: var(--headingColor);
    }
}
</style>