<template>
    <div class="inner-testimonial-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>Our Client Testimonial</span>
                <h2>Loved By Thousand Clients</h2>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="loved-box">
                        <ul class="client-self">
                            <li>
                                <img src="../../assets/images/clients/inner1.webp" alt="image">
                            </li>
                            <li>
                                <div class="content">
                                    <h6>Christian Aaron</h6>
                                    <p>Marketing Manager</p>
                                </div>
                            </li>
                        </ul>
                        <p class="style-p">Lorem ipsum dolor sit amet nsectetur ctus ullam corper mattis pulvinar dapibus consectetur adipiscing elitut elit telluluc consectetur adipiscing elit.</p>
                        <ul class="star">
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="loved-box">
                        <ul class="client-self">
                            <li>
                                <img src="../../assets/images/clients/inner2.webp" alt="image">
                            </li>
                            <li>
                                <div class="content">
                                    <h6>Harper Luna</h6>
                                    <p>Assistant Manager</p>
                                </div>
                            </li>
                        </ul>
                        <p class="style-p">Lorem ipsum dolor sit amet nsectetur ctus ullam corper mattis pulvinar dapibus consectetur adipiscing elitut elit telluluc consectetur adipiscing elit.</p>
                        <ul class="star">
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="loved-box">
                        <ul class="client-self">
                            <li>
                                <img src="../../assets/images/clients/inner1.webp" alt="image">
                            </li>
                            <li>
                                <div class="content">
                                    <h6>Jackson Mateo</h6>
                                    <p>Product Manager</p>
                                </div>
                            </li>
                        </ul>
                        <p class="style-p">Lorem ipsum dolor sit amet nsectetur ctus ullam corper mattis pulvinar dapibus consectetur adipiscing elitut elit telluluc consectetur adipiscing elit.</p>
                        <ul class="star">
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="loved-box">
                        <ul class="client-self">
                            <li>
                                <img src="../../assets/images/clients/inner3.webp" alt="image">
                            </li>
                            <li>
                                <div class="content">
                                    <h6>Abigail Sofia</h6>
                                    <p>Designer</p>
                                </div>
                            </li>
                        </ul>
                        <p class="style-p">Lorem ipsum dolor sit amet nsectetur ctus ullam corper mattis pulvinar dapibus consectetur adipiscing elitut elit telluluc consectetur adipiscing elit.</p>
                        <ul class="star">
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="loved-box">
                        <ul class="client-self">
                            <li>
                                <img src="../../assets/images/clients/inner4.webp" alt="image">
                            </li>
                            <li>
                                <div class="content">
                                    <h6>Michael Mason</h6>
                                    <p>CEO & Founder</p>
                                </div>
                            </li>
                        </ul>
                        <p class="style-p">Lorem ipsum dolor sit amet nsectetur ctus ullam corper mattis pulvinar dapibus consectetur adipiscing elitut elit telluluc consectetur adipiscing elit.</p>
                        <ul class="star">
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="loved-box">
                        <ul class="client-self">
                            <li>
                                <img src="../../assets/images/clients/inner5.webp" alt="image">
                            </li>
                            <li>
                                <div class="content">
                                    <h6>Violet Aurora</h6>
                                    <p>Area Manager</p>
                                </div>
                            </li>
                        </ul>
                        <p class="style-p">Lorem ipsum dolor sit amet nsectetur ctus ullam corper mattis pulvinar dapibus consectetur adipiscing elitut elit telluluc consectetur adipiscing elit.</p>
                        <ul class="star">
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialContent'
}
</script>

<style lang="scss">
.loved-box {
    background-image: url(../../assets/images/clients/inner-bg.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    margin: 10px 8px 25px 8px;
    .client-self {
        list-style: none;
        padding-left: 0;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        li {
            display: inline-block;
            padding-right: 20px;
            img {
                border-radius: 50%;
            }
            &:last-child {
                padding-right: 0;
            }
            .content {
                h6 {
                    font-size: 22px;
                    margin-bottom: 5px;
                }
                p {
                    color: var(--hoverColor);
                    margin-bottom: 0;
                }
            }
        }
    }
    .style-p {
        font-style: italic;
        font-weight: 500;
        color: #555555;
    }
    .star {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        li {
            display: inline-block;
            padding-right: 3px;
            color: var(--starColor);
            &:last-child {
                padding-right: 0;
            }
        }
    }
}
</style>