<template>
    <div class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-big-widget">
                        <div class="details-card">
                            <div class="details-image">
                                <img :src="blogDetail.banner" alt="image">
                            </div>
                            <ul class="blog-list">
                                <li>
                                    <i class="flaticon-calendar"></i>
                                    Nov 22,2022
                                </li>
                                <!-- <li>
                                    <i class="flaticon-comment"></i>
                                    01 Comments
                                </li> -->
                                <li>
                                    <i class="flaticon-view"></i>
                                    4.4k
                                </li>
                            </ul>
                            <h2>{{blogDetail.title}}</h2>
                            <p>{{blogDetail.paragraph1}}</p>
                            <p>{{blogDetail.paragraph2}}</p>
                            <p>{{blogDetail.paragraph3}}</p>
                        </div>
                        <div class="development">
                            <!-- <h4>What You Will Get Under This Service</h4> -->
                            <ul v-for="item in blogDetail.listOffering" :key="item.id">
                                <li>
                                    <i class="flaticon-full-stop"></i>
                                    {{ item.li }}
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="blog-self">
                            <div class="self-image">
                                <img src="../../assets/images/blog/user1.webp" alt="image">
                            </div>
                            <div class="content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus  ullam leoorem ipsum dolor sit amet, consectetur adipiscin.</p>
                                <h5>Christian Aaron</h5>
                                <span>Marketing Manager</span>
                            </div>
                            <div class="icon">
                                <i class="flaticon-quotation"></i>
                            </div>
                        </div> -->
                        <div class="blog-pragraph">
                            <p>{{blogDetail.paragraph4}}</p>
                            <p>{{blogDetail.paragraph5}} </p>
                        </div>
                        <div class="popular-tags">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="tags">
                                        <ul>
                                            <li>Popular Tags:</li>
                                            <li>
                                                <a>NLP</a>
                                            </li>
                                            <li>
                                                <a>AI Bots</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="social">
                                        <ul class="social-link">
                                            <li>Share On:</li>
                                            <li>
                                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="comments">
                            <h3>2 Comments</h3>
                            <div class="comments-box">
                                <div class="comment-image">
                                    <img src="../../assets/images/blog/user2.webp" alt="image">
                                </div>
                                <div class="content">
                                    <h5>Anthony Dylan</h5>
                                    <span>17 Aug, 2022, 12:54 pm </span>
                                    <p>Sed ut perspiciatis natus unde omnis iste natus error sit voluptatem ccusamrem aperiam, eaque ipsa quae ab illo inventore veri.</p>
                                    <a href="#" class="reply">Reply</a>
                                </div>
                            </div>
                            <div class="comments-box comments-box2">
                                <div class="comment-image">
                                    <img src="../../assets/images/blog/user3.webp" alt="image">
                                </div>
                                <div class="content">
                                    <h5>Anthony Dylan</h5>
                                    <span>17 Aug, 2022, 12:54 pm </span>
                                    <p>Sed ut perspiciatis natus unde omnis iste natus error sit voluptatem ccusamrem aperiam, eaque ipsa quae ab illo inventore veri.</p>
                                    <a href="#" class="reply">Reply</a>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="leave">
                            <h3>Leave A Comment</h3>
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <input type="text" class="form-control" placeholder="Your Name">
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="text" class="form-control" placeholder="Your Email">
                                    </div>
                                    <div class="col-lg-12">
                                        <input type="text" class="form-control" placeholder="Your Website">
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea  cols="30" rows="10" class="form-control" placeholder="Type Your Comment"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit" class="default-btn">Post Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="blog-small-widget">
                        <div class="input-box">
                            <form>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search Something" aria-label="Search..." aria-describedby="button-addon2">
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i class='bx bx-search'></i></button>
                                </div>
                            </form>
                        </div>
                        <div class="category">
                            <h4>Category</h4>
                            <ul>
                                <li>
                                    <a href="/blog-details?service=blog1">
                                         Are Chatbots worth the Investment?
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/blog-details?service=blog2">
                                        App or Website: Which is better to begin with?
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/blog-details?service=blog3">
                                        DevOps Services
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/blog-details?service=blog4">
                                        Why does design matter?About web design and current trends.
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                                <li>
                                    <a href="/blog-details?service=blog5">
                                        Everything about blockchain that you need to know!
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="post-box stil-box">
                            <h3>Popular Post</h3>
                            <div class="blog-post d-flex">
                                <router-link class="thumb" to="/blog-details?service=blog1">
                                    <span class="full-image cover bg-1"></span>
                                </router-link>
                                <div class="post-content">
                                    <p>
                                        <i class="flaticon-calendar"></i>
                                        Apr 22,2023
                                    </p>
                                    <h5>
                                        <a href="/blog-details?service=blog1">Are Chatbots worth the Investment?</a>
                                    </h5>
                                </div>
                            </div>
                            <div class="blog-post d-flex">
                                <router-link class="thumb" to="/blog-details?service=blog2">
                                    <span class="full-image cover bg-2"></span>
                                </router-link>
                                <div class="post-content">
                                    <p>
                                        <i class="flaticon-calendar"></i>
                                        May 22,2023
                                    </p>
                                    <h5>
                                        <a href="/blog-details?service=blog2">App or Website: Which is better to begin with?</a>
                                    </h5>
                                </div>
                            </div>
                            <div class="blog-post d-flex last-blog">
                                <router-link class="thumb" to="/blog-details?service=blog3">
                                    <span class="full-image cover bg-3"></span>
                                </router-link>
                                <div class="post-content">
                                    <p>
                                        <i class="flaticon-calendar"></i>
                                        Jun 22,2023
                                    </p>
                                    <h5>
                                        <a href="/blog-details?service=blog3">DevOps Services</a>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="details-tags">
                            <ul>
                                <li>
                                    <a>Agency</a>
                                </li>
                                <li>
                                    <a>Branding</a>
                                </li>
                                <li>
                                    <a>Design</a>
                                </li>
                                <li>
                                    <a>Marketing </a>
                                </li>
                                <li>
                                    <a>Development</a>
                                </li>
                                <li>
                                    <a>Consulting</a>
                                </li>
                                <li>
                                    <a>Financial</a>
                                </li>
                                <li>
                                    <a>Consulting Startup</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { blogs } from "../../constants/blogs";

export default {
    created() {
        this.getParam();
    },
    methods: {
        getParam() {
            // Accessing route params in the created hook
            const { service } = this.$route.query;
            this.blogDetail = service ? blogs[service?.toLocaleLowerCase()] : blogs["blog2"]
        },
    },
    data() {
        return {
            blogDetail: null,
        }
    },
    name: 'BlogDetails',
    
}

</script>

<style lang="scss">
.post-box {
    h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .blog-post {
        overflow: hidden;
        margin-bottom: 20px;
        align-items: center;
        background: #F4F8FC;
        border-radius: 5px;
        &:last-child {
            margin-bottom: 0;
        }
        .thumb {
            width: 120px;
            height: 120px;
            margin-right: 18px;
            position: relative;
            display: inline-block;
            .full-image {
                width: 120px;
                height: 120px;
                display: inline-block;
                position: relative;
                border-radius: 5px 0px 0px 5px;
                background: {
                    position: center center;
                    repeat: no-repeat;
                    size: cover;
                };

                &.bg-1 {
                    background-image: url("../../assets/images/blog/recent1.webp");
                }
                &.bg-2 {
                    background-image: url("../../assets/images/blog/recent2.webp");
                }
                &.bg-3 {
                    background-image: url("../../assets/images/blog/recent3.webp");
                }
            }
        }

        .post-content {
            p {
                font-weight: 400;
                font-size: 16px;
                color: var(--bodyColor);
                margin-bottom: 10px;
                i {
                    position: relative;
                    color: var(--hoverColor);
                    margin-right: 4px;
                    top: 2px;
                }
            }
            h5 {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0;
                a {
                    text-decoration: none;
                    color: var(--headingColor);
                    transition: var(--transition);
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
        
        &.last-blog {
            margin-bottom: 0px;
        }
    }
}
.details-tags {
    padding: 40px;
    background: #F4F8FC;
    border-radius: 5px;
    margin-top: 30px;
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            padding-right: 10px;
            margin-bottom: 10px;
            a {
                padding: 14px 18px ;
                color: #888888;
                display: inline-block;
                font-size: 15px;
                background-color: var(--whiteColor);
                &:hover {
                    background-color: var(--hoverColor);
                    color: var(--whiteColor);
                }
            }
            &:last-child {
                padding-right: 0;

            }
        }
    }
}
.details-card {
    margin-bottom: 30px;
    .details-image {
        margin-bottom: 30px;
        border-radius: 5px;
    }
    .details-image {
        img {
            border-radius: 5px;
        }
    }
    .blog-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 15px;
        li {
            display: inline-block;
            position: relative;
            padding-right: 20px;
            i {
                position: relative;
                color: #FF414B;
                top: 2px;
                margin-right: 5px;
            }
        }
    }
    h2 {
        font-size: 25px;
        letter-spacing: -0.03em;
        margin-bottom: 15px;
    }
}
.blog-self {
    position: relative;
    padding: 38px;
    background: #F4F8FC;
    border-radius: 5px;
    margin-bottom: 30px;
    .self-image {
        position: absolute;
        img {
            width: 125px;
            border-radius: 50%;
        }
    }
    .content {
        padding-left: 150px;
        p {
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
        }
        h5 {
            font-size: 22px;
            margin-bottom: 8px;
        }
        span {
            color: var(--hoverColor);
        }
    }
    .icon {
        position: absolute;
        right: 38px;
        bottom: 18px;
        i {
            font-size: 45px ;
            color: var(--mainColor);
        }
    }
}
.blog-pragraph {
    margin-bottom: 30px;
}
.popular-tags {
    margin-bottom: 80px;
    .tags {
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                display: inline-block;
                padding-right: 15px;
                font-weight: 500;
                font-size: 16px;
                color: var(--headingColor);
                a {
                    padding: 18px 21px ;
                    background: #F4F8FC;
                    border-radius: 5px;
                    display: inline-block;
                    font-weight: 400;
                    color: #888888;
                    &:hover {
                        background-color: var(--hoverColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
    .social {
        text-align: right;
        .social-link {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            li {
                display: inline-block;
                padding-right: 12px;
                font-weight: 500;
                font-size: 16px;
                color: var(--headingColor);
                &:last-child {
                    padding-right: 0;
                }
                a {
                    width: 32px;
                    height: 32px;
                    line-height: 37px;
                    color: var(--mainColor);
                    text-align: center;
                    display: inline-block;
                    font-size: 16px;
                    border-radius: 50%;
                    background: rgba(0, 100, 251, 0.1);
                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        transform: translateY(1.1);
                    }
                }
            }
        }
    }
}
.comments {
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 50px;
    margin-bottom: 30px;
    h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .comments-box {
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: solid 1px var(--borderColor);
        &.comments-box2 {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
            .reply {
                bottom: 80px;
            }
        }
        .comment-image {
            position: absolute;
            img {
                width: 100px;
                border-radius: 50%;
            }
        }
        .content {
            padding-left: 120px;
            p {
                font-size: 16px;
            }
            h5 {
                font-size: 18px;
                margin-bottom: 2px;
            }
            span {
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 14px;
                color: #888888;
                display: inline-block;
            }
        }
        .reply {
            position: absolute;
            right: 38px;
            bottom: 100px;
            border-radius: 5px;
            padding: 6px 12px;
            background: rgba(0, 100, 251, 0.1);
            color: var(--mainColor);
            transition: var(--transition);
            &:hover {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }
    
}
.leave {
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 6%);
    border-radius: 5px;
    padding: 45px 65px 50px 50px;
    h3 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .form-control {
        height: 60px;
        background: #F4F8FC;
        border-radius: 5px;
        color: #888888;
        padding-left: 18px;
        border: none;
        margin-bottom: 20px;
        &:focus {
            box-shadow: unset;
        }
        &::placeholder {
            color: #888888;
        }
    }
    textarea.form-control {
        min-height: 200px;
        padding-top: 15px;
    }
}
.blog-big-widget {
    padding-right: 20px;
}
</style>