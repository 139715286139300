<!-- <template>
    <div class="top-header-area bg-F4F8FC">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-8 col-md-9">
                    <div class="header-contact">
                        <ul>
                            <li>
                                <i class="flaticon-phone-call"></i>
                                Make a call :<a href="tel:3128959800">(312) 895-9800</a>
                            </li>
                            <li>
                                <i class="flaticon-pin"></i>
                                121 St, Melbourne VIC 3000, Australia
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-3">
                    <div class="top-social">
                        <ul>
                            <li>
                                Follow Us 
                            </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" target="_blank"><i class='bx bxl-twitter' ></i></a>
                            </li>
                            <li>
                                <a href="https://www.skype.com/" target="_blank"><i class='bx bxl-skype' ></i></a>
                            </li>
                            <li>
                                <a href="https://www.pinterest.com/" target="_blank"><i class='bx bxl-pinterest' ></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> -->

<script>
export default {
    name: 'TopHeader'
}
</script>

<style lang="scss">
.top-header-area {
    padding-top: 19px;
    padding-bottom: 19px;
}
.header-contact {
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            padding-left: 22px;
            padding-right: 30px;
            position: relative;
            &:last-child {
                padding-right: 0;
            }
            i {
                position: absolute;
                left: 0;
                top: 2px;
                color: var(--hoverColor);
            }
            a {
                padding-left: 5px;
                &:hover {
                    color: var(--hoverColor);
                }
            }
        }
    }
}
.top-social {
    text-align: end;
    ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            padding-left: 8px;
            i {
                font-size: 16px;
                color: var(--headingColor);
                transition: var(--transition);
                &:hover {
                    color: var(--hoverColor);
                }
            }
            &:first-child {
                padding-right: 0;
                padding-left: 5px;
                position: relative;
                top: -1px
            }
        }
    }
}
</style>