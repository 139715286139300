<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7" data-aos="zoom-in-up" data-aos-duration="1000">
                    <div data-tilt class="about-image">
                        <img src="../../assets/images/about/about1.webp" alt="image">
                        <img src="../../assets/images/shapes/banner-shape1.webp" class="about-shape" alt="image">
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="single-section about-content">
                        <span>About Our Company</span>
                        <h2>Over 10 Year Professional Experiences</h2>
                        <p>BITSmart provides you with tailored and transformative IT Solutions that cater to your unique
                            needs and propel your business forward. Our team of experienced professionals is at the
                            forefront of the ever-evolving tech landscape.</p>
                        <!-- <p class="last-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus ull
                            mattis pulvinar dapibus leoorem ipsum.</p> -->
                        <router-link to="/about" class="default-btn">More About Us</router-link>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/about-shape1.webp" class="about-shape2" alt="image">
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany'
}
</script>

<style lang="scss">
.about-content {
    margin-left: -45px;

    .last-p {
        margin-bottom: 25px;
    }
}

.about-image {
    margin-right: 45px;
    position: relative;

    .about-shape {
        position: absolute;
        top: 28px;
        left: 23%;
        -webkit-animation: movebounce 8s linear infinite;
        animation: movebounce 8s linear infinite;
    }
}

.about-area {
    position: relative;
    z-index: 1;

    .about-shape2 {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: -1;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .about-image {
        margin-right: 0;
        position: relative;
        margin-bottom: 30px;
    }

    .about-content {
        margin-left: 0;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-image {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .about-content {
        margin-left: 0;
    }
}</style>