<template>
    <div class="service-area pt-100 pb-75 bg-F4F8FC">
        <div class="container">
            <div class="section-title">
                <span>Services We Offer</span>
                <h2>Our Main Services</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1000" v-for="service in services"
                    :key="service.id">
                    <div class="service-card">
                        <div class="service-icon">
                            <img :src="service.showImage" class="shown" alt="svg">
                            <img :src="service.hiddenImage" class="hidden" alt="svg">
                        </div>
                        <div class="service-content">
                            <h4>
                                <router-link :to="`${service.serviceDetails}`">{{ service.title }}</router-link>
                            </h4>
                            <p>{{ service.desc }}</p>
                            <router-link :to="`${service.serviceDetails}`" class="read-more">Read More
                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/service-shape1.webp" class="service-shape" alt="image">
    </div>
</template>

<script>
export default {
    name: 'ServicesWeOffer',
    data() {
        return {
            services: [
                {
                    id: 1,
                    showImage: require("../../assets/images/svgs/service2.svg"),
                    hiddenImage: require("../../assets/images/svgs/hidden2.svg"),
                    title: "Web Development",
                    desc: "Creating websites and web apps with the use of the latest industry standards and best practices to get your brand known to the market and connect to the audience.",
                    serviceDetails: "/service-details?service=web development"
                },
                {
                    id: 2,
                    showImage: require("../../assets/images/svgs/service6.svg"),
                    hiddenImage: require("../../assets/images/svgs/hidden6.svg"),
                    title: "App Development",
                    desc: "We provide you with the best application customizations that cater to all your requirements and objectives, creating an innovative and high performance app.",
                    serviceDetails: "/service-details?service=app development"
                },
                {
                    id: 3,
                    showImage: require("../../assets/images/svgs/service4.svg"),
                    hiddenImage: require("../../assets/images/svgs/hidden4.svg"),
                    title: "UI/UX",
                    desc: "Combining creativity and the client's objectives to come up with a visually appealing, intuitive and user centric digital experience whilst focusing on growth.",
                    serviceDetails: "/service-details?service=uiux design"
                },
                {
                    id: 4,
                    showImage: require("../../assets/images/svgs/service1.svg"),
                    hiddenImage: require("../../assets/images/svgs/hidden1.svg"),
                    title: "Artificial Intelligence",
                    desc: "Enabling your company to get cutting edge AI - technologies and expertise to the tip of your hands,  without the need for extensive in-house AI infrastructure or expertise.",
                    serviceDetails: "/service-details?service=artificial intllegence"
                },
                {
                    id: 5,
                    showImage: require("../../assets/images/svgs/service5.svg"),
                    hiddenImage: require("../../assets/images/svgs/hidden5.svg"),
                    title: "Blockchain",
                    desc: "Introduce your business to a immutable document tracking and distribution channel which makes communication and transfer of data much more efficient",
                    serviceDetails: "/service-details?service=blockchain"
                },
                {
                    id: 6,
                    showImage: require("../../assets/images/svgs/service3.svg"),
                    hiddenImage: require("../../assets/images/svgs/hidden3.svg"),
                    title: "IT Resources",
                    desc: "IT Resources refers to our in house team coming to your office/workspace to help you build or maintain your resources providing you with the best background technical support on all fronts.",
                    serviceDetails: "/service-details?service=it resources"
                },
              
            ]
        }
    }
}
</script>

<style lang="scss">
.service-card {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 35px 36px;
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    transition: var(--transition);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 0;
        width: 100%;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
        background: linear-gradient(270deg, #0064FB -37.5%, #FF414B 126.18%);
    }

    .service-icon {
        margin-bottom: 20px;
        position: relative;

        img {
            &.hidden {
                position: absolute;
                left: 0px;
                opacity: 0;
                visibility: hidden;
                transition: var(--transition);
            }

            &.shown {
                transition: var(--transition);
            }
        }
    }

    .service-content {
        h4 {
            font-size: 22px;
            margin-bottom: 10px;

            a {
                color: var(--headingColor);
                transition: var(--transition);
            }
        }

        p {
            margin-bottom: 18px;
            transition: var(--transition);
        }

        .read-more {
            font-weight: 500;
            color: var(--hoverColor);
            position: relative;
            transition: var(--transition);

            img {
                filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                transition: var(--transition);
            }
        }


    }

    &:hover {
        &::before {
            opacity: 1;
            visibility: visible;
            height: 100%;
            transition: var(--transition);
        }

        .service-content {
            h4 {
                a {
                    color: var(--whiteColor);
                }
            }

            p {
                color: var(--whiteColor);
            }

            .read-more {
                color: var(--whiteColor);

                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
                    transition: var(--transition);
                }
            }
        }

        .service-icon {
            img {
                &.shown {
                    opacity: 0 !important;
                    visibility: hidden !important;
                }

                &.hidden {
                    opacity: 1 !important;
                    visibility: visible !important;
                }
            }
        }
    }
}

.service-area {
    position: relative;
    z-index: 1;

    .service-shape {
        position: absolute;
        left: 50px;
        top: 0;
        z-index: -1;
    }
}
</style>