export const blogs = {
    "blog1": {
      title: "Are Chatbots worth the Investment?",
      paragraph1:
        "Businesses are constantly seeking for ways to increase client interaction and strive to streamline operations in the age of the digital world since technology is constantly evolving and moving closer to a future with limitless marketing options. Virtual assistants backed by artificial intelligence, which is increasing, are revolutionising customer relationships. Do you believe investing in chatbots is worthwhile? Why are chatbots viewed as the market research of the future?",
        paragraph2: "A chatbot is a computer programme that mimics human communication by using artificial intelligence (AI) and natural language processing (NLP) to interpret client questions. Chatbots have revolutionised the client experience by offering 24/7, immediate assistance. The end outcome is greater brand loyalty, engagement, and customer pleasure. Chatbots are incredibly useful tools for collecting client information. They gain insightful information about customer preferences, problems, and purchasing patterns through discussions. This information can be utilised to improve marketing tactics, customise product selections, and provide individualised experiences. Businesses have a greater understanding of their clients and can make wise decisions to promote growth and profitability by analysing the enormous quantity of data generated by chatbots.",
        paragraph3: "We may categorise bots into two classes based on how they were programmed: Simple Chatbots (which operate using pre-written commands) and Smart Chatbots (trained or sophisticated).",
        paragraph4: "The reason why a business would require a chatbot is now in doubt. The solution is as easy as eliminating mundane operations and handling multiple user requests at once. In addition, the huge speed at which chatbots handle user requests are significantly boosting client loyalty to the brand. Customers use chatbots as well as their interest in this technology grows.",
        paragraph5:"To sum up, the investment in chatbots is well worth the rewards they bring Chatbots have become a potent tool for companies looking to improve customer experience, increase productivity, and spur growth in today's fast-paced digital environment.",
        
          listOffering: [
        { id: 1, li: "Front - End Framework" },
        { id: 2, li: "Back - End Framework" },
      ],
      banner: require('../assets/images/blog/recent1.webp'),
    },
    "blog2": {
        title: "App or Website: Which is better to begin with?",
        paragraph1:
          "In the growing digital world, all businesses and start-ups are busy making their websites and mobile Apps. The question that arises is which amongst the two takes your business to another level? The common consensus is that instead of laptops or other technology, everyone will have Mobile phones in the future.",
          paragraph2: "The rise of mobile phones has made mobile apps the newest thing. But should that be your sole starting point? Especially if one lacks the funding to launch both? Having a phone does not automatically translate into downloading mobile applications. So how do you dominate your rivals through website? Its highly crucial to be aware of the advantages and disadvantages which are involves in both.           ",
          paragraph3: "As a business it’s even more important to choose a right platform for your business to grow. The majority of web design and development firms will advise you to create a website first and a mobile app subsequently because it is the less expensive alternative. Instead of a website, startups like Ola and Uber use a mobile app. Similar to the previous example, if you want to create a route tracking app, the only way to give your customers the best experience is through a mobile app because GPS is a company's lifeblood. Mobile apps will attract more users if you plan to develop an Android application like notes, alarm, or social media. All enterprises only have a limited number of resources at first. You can choose more platforms as your business grows. However, a business that decides to focus entirely on apps runs the risk of alienating a sizable segment of the market. Especially if their app is not one of the top few apps a user chooses.",
          paragraph4: "Myntra, the top fashion e-commerce site in India, is a well-known example. It declared that it was switching to an app-only model a few years ago. The business stated that this action was done to enhance personalisation. Without a doubt, the devoted Myntra users were pressured to download the mobile app. To eliminate the competition, this strategy was used.        Before making any decision firstly, we must establish the main goals and features of the project. A website can be more appropriate if the primary objective is to distribute material or provide information. An app might be a better option, though, if you need advanced features, real-time updates, or interactivity.          ",
          paragraph5:"Secondly, Consider the preferences and actions of your target audience. Which channel will people use to interact with your product more frequently, a mobile app or a website? Websites may be accessed on any device with a browser, making them typically more accessible. In contrast, apps frequently place restrictions on accessibility based on the operating system and demand that users have compatible. Developing and maintaining an app often requires more resources and investment compared to a website. App development typically involves multiple platforms (iOS, Android), and ongoing maintenance and updates can be time-consuming and expensive. Websites, on the other hand, are generally more cost-effective to develop and maintain.          ",
        banner: require('../assets/images/blog/recent2.webp'),
      },
      "blog3": {
        title: "DevOps Services",
        paragraph1:
          "DevOps is an approach that works in relation with software developers and operations. It was developed in 2009 with the motive of improving communication and integration development and operations in order to benefit fully from modern software development approaches. DevOps is an agent of change in the current business environment, which supports complex applications. In other words, this is a delivery model for a set of tools that makes it easier for the operations team and the software development team of an organisation to work together. The DevOps as a Service provider delivers the many tools that cover different facets of the entire process and integrates these tools so they may operate as a single entity. Every activity taken throughout the software delivery process should be able to be tracked, according to DevOps as a Service.",
          paragraph2: " DevOps as a Service guarantee that software is completely tested and error-free before it is deployed by automating testing and deployment procedures.",
          paragraph3: "Software is delivered more quickly , which also helps businesses accelerate their digital transformation. Companies may concentrate on their core business operations and meet their objectives more quickly by utilising the skills and experience of a DevOps service provider.",
          paragraph4: "DevOps as a Service provide a variety of tools and services that assist businesses in raising the quality of their software.  ",
          paragraph5:"DevOps as a Service providers are frequently cost-effective  Businesses can cut IT spending and enhance the ROI of their technology investments by implementing DevOps as a Service. DevOps as a Service companies provide scalable and adaptable solutions that may be adjusted to an organization's specific needs.",
          
            listOffering: [
          { id: 1, li: "The focus is more on people. The DevOps framework explores the challenge of serving the dynamically changing and business critical architectures of today’s technology" },
          { id: 2, li: "DevOps is driven by metrics, use lean startup methodologies, evaluating ideas, providing valuable data to decision makers." },
          { id: 3, li: "DevOps fosters a culture of continuous learning and improvement." },
          { id: 4, li: "DevOps promotes the continuous monitoring of software applications and infrastructure to gain insights into performance, availability, and user experience." },

        ],
        banner: require('../assets/images/blog/recent3.webp'),
      },
      "blog4": {
        title: "Why does design matter?About web design and current trends. ",
        paragraph1:
          "People usually ask why design matters?Why should the design look good and systematic? Does it really matter? Is it going to add value to the company? Well! In our opinion, Design matters in order to grow the business. Let's dive in and see why?         Design is everywhere. It shapes our world and plays a crucial role in our daily lives. From the clothes we wear to the products we use. Designers are always crucial in helping businesses convince their clients of the worth of their ideas. The psychological influence that a good design creates is the first and foremost.A well-designed product has the power to capture our attention and generate interest. A good website or an eye-catching advertisement instantly communicates a message, evokes emotion, and sparks curiosity. It is our first impression of a brand or a product and is often the deciding factor on whether we want to engage with it or not. Design also goes beyond aesthetics. It creates trust, reliability, and increases credibility.",
          paragraph2: "A well-designed product communicates attention to detail, leaving customers with a sense of confidence in the robustness of a service. Design plays a significant role in enhancing user experience.An intuitive and smooth experience is produced by a well-designed user interface (UI) and user experience (UX), making it simpler for users to browse, comprehend, and carry out tasks.Design distinguishes the business from their rivals and creates a distinctive brand. Good design creates brand value, evokes emotion, and creates a meaningful connection with users. It helps establish a reputation for quality, desirability, and reliability.As a designer, you have the ability to control the user's actions, thoughts and even emotions. It’s your design that says it all about how your website is going to perform.Design is about grabbing the user’s attention to the things you want. Design is important in many different ways. It affects the choices we make, improves our experiences, resolves issues, and forges emotional ties. Moving onto what is web design and what are the current trends that you need to know. Web designing is the process of planning, envisioning, and putting a strategy in place for building a website in a way that is practical. The user experience is at the foundation of web design. A variety of features are displayed on websites in ways that make them easy to navigate. It basically entails working on every aspect of a website that users interact with to make it easy and effective, let visitors to locate the information they need fast, and appear aesthetically appealing. Together, these elements determine how effectively the website is constructed.   ",
        //   paragraph3: "We may categorise bots into two classes based on how they were programmed: Simple Chatbots (which operate using pre-written commands) and Smart Chatbots (trained or sophisticated).",
          paragraph4: "Staying up to date with design trends can help businesses stand out among competition and offer unique and memorable experiences to customers.Although some of these trends may be new in the coming future, web designers are already utilising some of them in their ongoing quest to provide the greatest user experience. These modern web design trends will undoubtedly be important in the process of developing the greatest experiences for mobile devices in addition to websites for desktop.",
        //   paragraph5:"To sum up, the investment in chatbots is well worth the rewards they bring Chatbots have become a potent tool for companies looking to improve customer experience, increase productivity, and spur growth in today's fast-paced digital environment.",
          
            listOffering: [
          { id: 1, li: "Dark mode: It reduces eye strain, saves battery life and offers a sleek and modern look to websites and apps." },
          { id: 2, li: "GIFs, Illustrations, and Animation: Earlier, developers used motion pictures to display information but it was soon realised that adding motion pictures to the website actually took more loading time of the website and made them slow. Motion graphics were thus developed as an alternative to this. The fact that Motion Graphics doesn't take much time to load the website." },
          { id: 3, li: "Voice User Interface (VUI): There are AI assistants like Siri, Alexa, and more that sense human voice and act accordingly.VUI helps you to take your website to the next level." },
          { id: 4, li: "Asymmetric Layouts: Most websites have a simple, symmetrical style that makes it easy for users to read and comprehend the content.By defying such conventions, designers logically adopt asymmetrical layouts and creative touches to make it more interesting for visitors to read the material. Broken grid architecture, which asymmetrical layouts symbolise, is appealing for its uniqueness and decisiveness." },
          { id: 5, li: "Virtual Chatbots: Virtual Chatbots are quite popular right now, and many large companies use them to respond to consumer inquiries.In addition to providing typical answers, chatbots may engage in conversation with users, giving the impression of a Facebook Messenger conversation." },
          { id: 6, li: "Minimalistic design: This trend focuses on simplicity and functionality, with a clean and uncluttered interface. It creates a better user experience by eliminating distractions and making navigation easier." },
        ],
        banner: require('../assets/images/blog/recent4.webp'),
      },
      "blog5": {
        title: "Everything about blockchain that you need to know!",
        paragraph1:
          "You might have heard the term “Blockchain Technology” in the context of cryptocurrencies before. Do you know what is blockchain technology. If you still don’t know anything about this technology. This website is the best place to learn the basics of blockchain. In this blog you will discover what is blockchain, how blockchain functions, why it’s crucial, types of blockchain network and many more. As this technology continues to grow and become more suer friendly, its beneficial for you and your coming future if you learn about this.",
          paragraph2: "Blockchain network threats must be recognised and managed in order to maintain blockchain security. A blockchain security model is the strategy for adding security to these controls. To make sure that all safeguards are in place to appropriately protect your blockchain solutions, create a blockchain security model. Administrators must create a risk model that can manage all business, governance, technological, and process concerns in order to execute a blockchain solution security model. The next step is to assess the risks associated with the blockchain solution and develop a threat model. Then, depending on the following three categories, administrators must establish the security measures that reduce the risks and threats",
          paragraph3: "Blockchain technology is a technique for storing data and makes it impossible for the system to be hacked or use the information otherwise. Blockchain technology is an arrangement that maintains data related to public transactional known as the “Block” and in a number of databases, known as “chain”, in a network connected by peer-to-peer nodes. A “Digital ledger” is a common word used for this type of storage. A digital ledger is similar to google spreadsheet shared among numerous computers in which there are transactional records stored on the basis of actual purchases. The Twisting part here is that anyone can see the data but no one can corrupt the data. Isn’t that fascinating?",
          paragraph4: "It is one of the networks used by Bitcoin. Public Blockchain network is a network that anybody may join and use. Possible disadvantages include the need for a lot of processing power, a lack of privacy for transactions and inadequate security. These are some of the important considerations to take into account for enterprise blockchain use cases.",
          paragraph5:"A private blockchain network is analogous to a public blockchain network. It is a decentralized peer to peer network. A single organization, however, controls the network's governance, executing a consensus procedure and managing the shared ledger. Depending on the use case, this can greatly increase participant confidence and trust.",
          
            listOffering: [
          { id: 1, li: "Enforce blockchain-specific security measures" },
          { id: 2, li: "Implement customary security measures" },
        ],
        banner: require('../assets/images/blog/recent5.webp'),
      },
  };
  


  