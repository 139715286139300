<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="about-image">
                        <img src="../../assets/images/about/about3.webp" alt="image">
                        <img src="../../assets/images/shapes/about-shape3.webp" class="about-shape-03" alt="image">
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="single-section about-content">
                        <p class="title-tag">
                            <i class="flaticon-full-stop"></i>
                            ABOUT OUR COMPANY
                        </p>
                        <h2>Over 12 Year Professional Experiences</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus  ullamcorper mattis pulvinar dapibus leoorem ipsum dolor sit amet, consectetu adipiscing elitut elit tellu luctus pulvinar dapibus leo.</p>
                        <p class="last-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus luctus ull mattis pulvinar dapibus leoorem ipsum.</p>
                        <router-link to="/about" class="default-btn">More About Us</router-link>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/about-shape1.webp" class="about-shape04" alt="image">
    </div>
</template>

<script>
export default {
    name: 'AboutOurCompany'
}
</script>

<style lang="scss">
.about-shape-03 {
    position: absolute;
    left: 70px;
    top: -30px;
    z-index: -1;
}
</style>