<template>
    <div class="team-area-style2 ptb-100">
        <div class="container">
            <div class="section-title">
                <ul class="section-style">
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                    <li>
                        Meet Our Team Members
                    </li>
                    <li>
                        <i class="flaticon-remove"></i>
                        <i class="flaticon-remove"></i>
                    </li>
                </ul>
                <h2>Meet Our Experienced Team People</h2>
            </div>
            <div class="team-style-slider">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                    ref="carousel"
                >
                    <Slide 
                        v-for="team in teams"
                        :key="team.id"
                    >
                        <div class="member-box">
                            <div class="image">
                                <img :src="team.image" alt="image">
                            </div>
                            <div class="content d-flex align-items-center">
                                <div class="self">
                                    <h4>{{team.name}}</h4>
                                    <p>{{team.position}}</p>
                                </div>
                                <div class="social-list">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.skype.com/" target="_blank"><i class="bx bxl-skype"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a>
                                            </li>
                                        <li>
                                            <a href="https://www.pinterest.com/" target="_blank"><i class="bx bxl-pinterest-alt"></i></a>
                                        </li>
                                    </ul>
                                    <div class="share-icon">
                                        <i class="flaticon-share"></i>
                                    </div>
                                </div>
                            </div>
                            <img src="../../assets/images/shapes/team.webp" class="team-shape0" alt="image">
                        </div>
                    </Slide>
                </carousel>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="custom-navigation">
                        <button class="carousel-prev" @click="prev"><img src="../../assets/images/svgs/slider2.svg" alt="svg"></button>
                        <button class="carousel-next" @click="next"><img src="../../assets/images/svgs/slider1.svg" alt="svg"></button>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="style-link">
                        <router-link to="/team" class="all-case">See All Team Member
                            <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/banner-shape1.webp" class="about-shape03" alt="image">
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'OurTeamMembers',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        teams: [
            {
                id: 1,
                image: require("../../assets/images/team/member1.webp"),
                name: 'Jonathan Santiago',
                position: 'Product Manager',
            },
            {
                id: 2,
                image: require("../../assets/images/team/member2.webp"),
                name: 'Charlotte Amelia',
                position: 'Co-Founder',
            },
            {
                id: 3,
                image: require("../../assets/images/team/member3.webp"),
                name: 'Christian Aaron',
                position: 'Marketing Manager',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
    methods: {
        next() {
            this.$refs.carousel.next()
        },
        prev() {
            this.$refs.carousel.prev()
        },
    }
})
</script>

<style lang="scss">
.member-box {
    position: relative;
    padding: 0 20px 0;
    margin-top: 22px;
    margin-bottom: 30px;
    text-align: left;
    
    .social-list {
        background-color: transparent;
        border: none;
        &:hover {
            ul {
                bottom: 175px;
                opacity: 1;
                visibility: visible;
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            position: absolute;
            bottom: 80px;
            right: 50px;
            transition: var(--transition);
            opacity: 0;
            visibility: hidden;
            li {
                margin-bottom: -10px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    width: 70px;
                    height: 60px;
                    display: block;
                    background: #0064FB;
                    text-align: center;
                    color: #fff;
                    font-size: 24px;
                    line-height: 65px;
                    border-radius: 5px;
                    transition: all ease 0.8s;
                    i {
                        color: var(--whiteColor);
                        transition: var(--transition);
                    }
                }
            }
        }
        .share-icon {
            width: 70px;
            height: 70px;
            display: block;
            background-color: #0064FB;
            margin-right: 30px;
            border-radius: 5px;
            text-align: center;
            line-height: 74px;
            font-size: 20px;
            transition: all ease 0.8s;
            margin-top: -100px;
            i {
                color: #FFFFFF;
            } 
            
        }
    }
    .content {
        justify-content: space-between;
        justify-content: space-between;
        padding: 30px 0 0;
        .self {
            h4 {
                font-size: 24px;
            }
            p {
                color: var(--hoverColor);
            }
        }
    }
    .team-shape0 {
        position: absolute;
        top: -20px;
        left: 0;
        z-index: -1;
        opacity: 10%;
        transition: var(--transition);
    }
    &:hover {
        .team-shape0 {
            opacity: 100%;
        }
    }
    image {
        img {
            border-radius: 5px;
        }
    }
}
.team-area-style2 {
    position: relative;
    
    .team-slider {
        .carousel {
            text-align: left;
            .carousel__viewport  {
                .carousel__slide  {
                    padding: 0 10px;
                }
            }
        }
    }
    .team-style-slider {
        .member-box {
            
        }
    }
    .style-link {
        text-align: end;
        margin-bottom: 0;
        display: block;
        position: relative;
        z-index: 9;
        top: -15px;
        a {
            font-weight: 500;
            color: #0064FB;
            &:hover {
                color: var(--hoverColor);
                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                }
            }
        }
    } 
    .section-title {
        max-width: 550px;
        margin-right: auto;
        margin-left: auto;
    }
    .about-shape03 {
        position: absolute;
        top: 13%;
        right: 26%;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .member-box {
        .content {
            .self {
                h4 {
                    font-size: 20px;
                }
            }
        }
    }
}
</style>