<template>
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="coming-soon-content">
                    <router-link to="/" class="logo">
                        <img src="../../assets/images/logo.png" alt="image">
                    </router-link>
                    
                    <h2>We Are Launching Soon</h2>
                    
                    <div id="timer" class="flex-wrap justify-content-center d-flex">
                        <div
                            id="days"
                            class="align-items-center flex-column d-flex justify-content-center"
                        >
                            {{days}}
                            <span>Days</span>
                        </div>
                        <div
                            id="hours"
                            class="align-items-center flex-column d-flex justify-content-center"
                        >
                            {{hours}}
                            <span>Hours</span>
                        </div>
                        <div
                            id="minutes"
                            class="align-items-center flex-column d-flex justify-content-center"
                        >
                            {{minutes}}
                            <span>Minutes</span>
                        </div>
                        <div
                            id="seconds"
                            class="align-items-center flex-column d-flex justify-content-center"
                        >
                            {{seconds}}
                            <span>Seconds</span>
                        </div>
                    </div>

                    <form class="newsletter-form">
                        <div class="form-group">
                            <input type="email" placeholder="Enter Your Email Address" id="name" class=" input-newsletter form-control" required>
                            <span class="label-title"></span>
                        </div>
                        
                        <button type="submit" class="default-btn">
                            Subscribe Now
                        </button>

                        <p>If you would like to be notified when your app is live, Please subscribe to our mailing list.</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComingSoonPage',
    data() {
        return {
            days: '',
            hours: '',
            minutes: '',
            seconds: ''
        }
    },
    created() { // Turn data into viewable values
        setInterval(() => {
            this.commingSoonTime();
        }, 1000);
    },
    methods: {
        commingSoonTime() {
            let endTime = new Date("December 5, 2025 17:00:00 PDT");            
            let endTimeParse = (Date.parse(endTime)) / 1000;
            let now = new Date();
            let nowParse = (Date.parse(now) / 1000);
            let timeLeft = endTimeParse - nowParse;
            let days = Math.floor(timeLeft / 86400);
            let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
            let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
            let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
            if (hours < "10") { hours = "0" + hours; }
            if (minutes < "10") { minutes = "0" + minutes; }
            if (seconds < "10") { seconds = "0" + seconds; }
            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
        }
    },
}
</script>

<style lang="scss">
.coming-soon-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background-image: url(../../assets/images/banner/comming-soon.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
    .d-table {
        width: 100%;
        height: 100%;
        .d-table-cell {
            vertical-align: middle;
        }
    }
    .coming-soon-content {
        max-width: 700px;
        background-color: #ffffff;
        border-radius: 15px;
        overflow: hidden;
        text-align: center;
        padding: 40px 60px;
        box-shadow: rgba(17, 17, 26, 10%) 0px 0px 16px;
        margin-left: 300px;
        margin-top: auto;
        margin-right: auto;
        margin-left: auto;;
        margin-bottom: auto;
        .logo {
            display: inline-block;
        }
        h2 {
            font-size: 45px;
            margin-top: 30px;
            margin-bottom: 0;
            color: var(--headingColor);
        }
        #timer {
            margin-top: 40px;
            div {
                background-color: #f9f9f9;
                color: var(--blackColor);
                width: 100px;
                height: 105px;
                border-radius: 5px;
                font-size: 40px;
                font-weight: 800;
                margin-left: 10px;
                margin-right: 10px;
                span {
                    font-size: 16px;
                    color: #666666;
                    display: block;
                    margin-top: -10px;
                }
            }
        }
        form {
            position: relative;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
            .form-group {
                margin-bottom: 25px;
                width: 100%;
                position: relative;
                .input-newsletter {
                    border-radius: 0;
                    border: none;
                    background-color: transparent;
                    border-bottom: 1px solid #5D5D5D;
                    transition: var(--transition);
                    color: var(--headingColor);
                    padding: 0 0 0 0px;
                    height: 45px;
                    display: block;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 400;
                    &:focus {
                        box-shadow: unset;
                    }
                }
                .label-title {
                    margin-bottom: 0;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    width: 110%;
                    height: 110%;
                    pointer-events: none;
                    color: var(--headingColor);
                    i {
                        position: absolute;
                        left: 0;
                        transition: .5s;
                        top: 9px;
                        font-size: 22px;
                    }
                }
            }
            .default-btn {
                &.disabled {
                    border: none;
                    font-size: 16px;
                    border-radius: 0;
                    padding: 12px 40px;
                }
            }
            p {
                margin-bottom: 0;
                margin-top: 20px;
            }

        }
    }
}
</style>