<template>
    <div 
        @click="scrollToTop()"
        :class="['progress-wrap', {'active-progress': isTop}]"
    >
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
		</svg>
    </div> 
</template>

<script>
export default {
    name: 'BackToTop',
    data (){
        return {
            isTop: false
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isTop = true
            } else {
                that.isTop = false
            }
        })
    }
}
</script>

<style lang="scss">
.progress-wrap {
    position: fixed;
    right: 15px;
    bottom: 50px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 30px 0px #96969696;
            box-shadow: 0px 0px 30px 0px #96969696;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    &.active-progress {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    &::after {
        position: absolute;
        content: '↑';
        text-align: center;
        line-height: 46px;
        font-size: 24px;
        color: var(--mainColor);
        left: 0;
        top: 0;
        height: 46px;
        width: 46px;
        cursor: pointer;
        display: block;
        z-index: 1;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
    }
    &:hover {
        &::after {
            opacity: 0;
        }
    }
    &::before {
        position: absolute;
        content: '↑';
        text-align: center;
        line-height: 46px;
        font-size: 30px;
        opacity: 0;
        left: 0;
        top: 0;
        height: 46px;
        width: 46px;
        cursor: pointer;
        display: block;
        z-index: 2;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
    }
    &:hover {
        &::before {
            opacity: 1;
        }
    }
}
.progress-wrap svg path {
    fill: none;
}
.progress-wrap svg.progress-circle path {
    stroke: var(--mainColor);
    stroke-width: 4;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
@-webkit-keyframes top-bottom {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
}
@keyframes top-bottom {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
}
</style>