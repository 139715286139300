<template>
    <div class="latest-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <p class="title-tag">
                    <i class="flaticon-full-stop"></i>
                    OUR CASE STUDY
                </p>
                <h2>Our Latest Incredible Client's Case Study</h2>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="frist-widget">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="latest-box">
                                    <img src="../../assets/images/projects/latest1.webp" alt="image">
                                    <div class="latest-content  d-flex">
                                        <div class="content">
                                            <h5>
                                                <router-link to="/case-study-details">
                                                    Infrastructure Upgrade 
                                                </router-link>
                                            </h5>
                                            <p>Game Development</p>
                                        </div>
                                        <div class="explore-btn">
                                            <router-link to="/case-study-details">Exolore
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="latest-box">
                                    <img src="../../assets/images/projects/latest2.webp" alt="image">
                                    <div class="latest-content  d-flex">
                                        <div class="content">
                                            <h5>
                                                <router-link to="/case-study-details">
                                                    AR/VR War 1986 Game
                                                </router-link>
                                            </h5>
                                            <p>Game Development</p>
                                        </div>
                                        <div class="explore-btn">
                                            <router-link to="/case-study-details">Exolore
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="second-widget">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="view-project">
                                    <router-link to="/case-study-details">See All Projects
                                        <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="latest-box">
                                    <img src="../../assets/images/projects/latest3.webp" alt="image">
                                    <div class="latest-content  d-flex">
                                        <div class="content">
                                            <h5>
                                                <router-link to="/case-study-details">
                                                    Creative Landing Page
                                                </router-link>
                                            </h5>
                                            <p>UI/UX Design</p>
                                        </div>
                                        <div class="explore-btn">
                                            <router-link to="/case-study-details">Exolore
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="latest-box">
                                    <img src="../../assets/images/projects/latest4.webp" alt="image">
                                    <div class="latest-content d-flex">
                                        <div class="content ">
                                            <h5>
                                                <router-link to="/case-study-details">
                                                    Analys & Backup Blockchain
                                                </router-link>
                                            </h5>
                                            <p>Data security , IT Consultation</p>
                                        </div>
                                        <div class="explore-btn">
                                            <router-link to="/case-study-details">Exolore
                                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/last.png" class="last-shape" alt="image">
    </div>
</template>

<script>
export default {
    name: 'OurCaseStudy'
}
</script>

<style lang="scss">
.latest-area {
    position: relative;
    z-index: 1;

    .section-title {
        text-align: center;
        margin-bottom: 30px;
        max-width: 705px;
        margin-right: auto;
        margin-left: auto;
    }
    .last-shape {
        position: absolute;
        top: 13%;
        z-index: -1;
        left: 13%;
    }
}
.second-widget {
    .view-project {
        text-align: end;
        margin-bottom: 50px;
        padding-right: 10px;
        a {
            font-weight: 500;
            color: #0064FB;
            text-decoration: underline;
            z-index: 1;
            position: relative;
            &:hover {
                color: var(--hoverColor);
                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                    transition: var(--transition);
                }
            }
            &::before {
                position: absolute;
                content: "";
                top: -17px;
                left: -30px;
                bottom: 0;
                width: 60px;
                height: 60px;
                z-index: -1;
                border-radius: 50%;
                background: #F4F8FC;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 5%);
            }
        }
    }
}
.latest-box {
    position: relative;
    margin-bottom: 25px;
    padding: 10px;
    .latest-content {
        position: absolute;
        background: #fff;
        padding: 40px;
        left: 40px;
        bottom: 40px;
        right: 40px;
        justify-content: space-between;
        align-items: center;
        .content {
            h5 {
                font-size: 22px;
                margin-bottom: 8px;
                a {
                    color: var(--headingColor);
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
            p {
                margin-bottom: 0;
            }
        }
        .explore-btn {
            a {
                padding: 15px 29px;
                border: 1px solid #EEEEEE;
                border-radius: 5px;
                font-weight: 500;
                display: inline-block;
                color: #0064FB;
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    img {
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(12%) hue-rotate(197deg) brightness(105%) contrast(104%);
                        transition: var(--transition);
                    }
                }
            }
        }
    } 
    img {
        border-radius: 5px;
    }
}
</style>