<template>
    <div class="uncommon-service ptb-100 bg-F4F8FC">
        <div class="container">
            <div class="service-section">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-section">
                            <p class="title-tag">
                                <i class="flaticon-full-stop"></i>
                                SERVICES WE OFFER
                            </p>
                            <h2>Our Main Services</h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="service-view-link">
                            <router-link to="/services" class="view-all">See All Services
                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inner-service">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="service-card service-card3">
                            <div class="service-icon">
                                <img src="../../assets/images/svgs/it-1.svg" class="shown" alt="svg">
                                <img src="../../assets/images/svgs/it-01.svg" class="hidden" alt="svg">
                            </div>
                            <div class="service-content">
                                <h4>
                                    <router-link to="/service-details">IT Consultation</router-link>
                                </h4>
                                <p>Lorem ipsum dolor sit amet consectetu radi  dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.</p>
                                <router-link to="/service-details" class="read-more">Read More
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="service-card service-card3">
                            <div class="service-icon">
                                <img src="../../assets/images/svgs/it-2.svg" class="shown" alt="svg">
                                <img src="../../assets/images/svgs/it-02.svg" class="hidden" alt="svg">
                            </div>
                            <div class="service-content">
                                <h4>
                                    <router-link to="/service-details">Software Development</router-link>
                                </h4>
                                <p>Lorem ipsum dolor sit amet consectetu radi  dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.</p>
                                <router-link to="/service-details" class="read-more">Read More
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-card service-card3">
                            <div class="service-icon">
                                <img src="../../assets/images/svgs/it-3.svg" class="shown" alt="svg">
                                <img src="../../assets/images/svgs/it-03.svg" class="hidden" alt="svg">
                            </div>
                            <div class="service-content">
                                <h4>
                                    <router-link to="/service-details">Data Security</router-link>
                                </h4>
                                <p>Lorem ipsum dolor sit amet consectetu radi  dapibus leoorem ipsum dolor sit amet consec luctus pulvinar dapibus leo.</p>
                                <router-link to="/service-details" class="read-more">Read More
                                    <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img src="../../assets/images/shapes/service-shape1.webp" class="service-shape" alt="image">
    </div>
</template>

<script>
export default {
    name: 'ServicesWeOffer'
}
</script>

<style lang="scss">
.inner-service {
    border: 1px solid #DDDDDD;
    background-color: var(--whiteColor);
    .service-card3 {
        margin-bottom: 0;
        padding: 50px 45px;
        .service-icon {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            text-align: center;
            line-height: 100px;
            transition: var(--transition);
            background: #F4F8FC;
            .hidden {
                position: absolute;
                left: 25px;
                top: 25px;
                right: 0;
                text-align: center;
                transition: all ease 0.8s;
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 0;
            width: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            background-color: var(--mainColor);
        }
        &::before {
            display: none;
        }
        &:hover {
            &::after {
                opacity: 1;
                visibility: visible;
                height: 100%;
                transition: var(--transition);
            }
            .service-icon {
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }
}
.service-section {
    margin-bottom: 20px;
    .service-view-link {
        text-align: end;
        a {
            font-weight: 500;
            color: #0064FB;
            text-decoration: underline;
            z-index: 1;
            position: relative;
            &:hover {
                color: var(--hoverColor);
                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                    transition: var(--transition);
                }
            }
            &::before {
                position: absolute;
                content: "";
                top: -17px;
                left: -30px;
                bottom: 0;
                width: 60px;
                height: 60px;
                z-index: -1;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 5%);
            }
        }
    }
}
</style>